/**
 * Routing module.
 * @module routing
 */

"use strict";

import * as Gui from './core/gui';
import {createNewEvent} from './core/helpers';
import {log} from './core/debug';
import {Url} from './core/url';
import {HomeView} from './views/home';
import {MedSwitchView} from './views/medswitch';
import {MedCombineView} from './views/medcombine';
import {MedSwitchResultView} from './views/medswitchresult';
import {MedCombineResultView} from './views/medcombineresult';
import {BenzoConversionView} from './views/benzoconversion';
import {BenzoConversionResultView} from './views/benzoconversionresult';
import {InfoView} from './views/info';
import {DebugView} from './views/debug';
import {FavoritesView} from './views/favorites';
import * as Session from './core/session';

/**
 * List of Urls.
 * @private
 */
let urls = [];

/**
 * The current Url object
 * @private
 */
let currentUrl;

/**
 * The current Url string, including parameters
 */
let currentUrlString;

/**
 * Initialize the routing, setting up all the urls.
 * @code Example:
 * <pre>
 * urls = [
 *     new Url('/form/', TestView, 'render'),
 *     new Url('/form/result/', TestView, 'formSubmit'),
 * ];
 * </pre>
 */
export function initRouting() {
    log('Initializing Routing');
    urls = [
        new Url('/', HomeView),
        new Url('/#/', HomeView),
        new Url('/#/switch/:type', MedSwitchView),
        new Url('/#/switch-result/:from/:to', MedSwitchResultView),
        new Url('/#/combine', MedCombineView),
        new Url('/#/combine-result/:existing/:add', MedCombineResultView),
        new Url('/#/benzo-conversion', BenzoConversionView),
        new Url('/#/benzo-conversion-result', BenzoConversionResultView),
        new Url('/#/info', InfoView),
        new Url('/#/debug', DebugView),
        new Url('/#/favorites', FavoritesView),
    ];


    /**
     * @typedef {Object} Window
     * @property {function} visit
     * @property {function} refresh
     */

    /**
     * Add new properties to the global window object.
     * @type {Window}
     */
    window.visit = visit;
    /**
     * Add new properties to the global window object.
     * @type {Window}
     */
    window.refresh = refresh;
}

/**
 * Try to visit the given url. Sequentially tries to match the given url to
 * all the Urls provided in [[Routing.urls]]. If there's a match it will
 * visit that url by calling its [[Url.visit]]
 * @param {string} url - the url, without the domain name
 * @param {Event} event - the event triggering this visit call
 * @param {object} data - optional form data object
 * @param {boolean} addToHistory - whether to push the current state
 * @global
 */
export function visit(url, event = createNewEvent(''), data=false, addToHistory=true) {
    log(`Try to visit ${url}`);
    let targetUrl;

    try {
        let matchedUrl = matchUrl(url);
        targetUrl = matchedUrl;
    } catch (e){
        Gui.show404();
        return log(`Could not match "${url}"`);
    }

    let prevUrl = '/';

    if(currentUrl && currentUrl.hasOwnProperty('url')) {
        prevUrl = window.location.pathname + window.location.hash;
    }

    if(currentUrl && currentUrlString === url) {
        log('Refreshing current url, skip adding it to the history');
        addToHistory = false;
    }

    if(addToHistory) {
        window.history.pushState({}, '', url);
    }

    if(currentUrl){
        currentUrl.destructor();
    }

    currentUrlString = url;
    currentUrl = targetUrl;
    targetUrl.visit(url, event, data);
    window.scrollTo(0, 0);
}

/**
 * Refresh the current page. This function properly destructs the current view
 * before visiting it again. This function is useful after doing custom
 * rendering (i.e in case of error / connection drops)
 */
export function refresh() {
    if(currentUrlString){
        visit(currentUrlString);
    }
}

/**
 * matchUrl takes an url and tries to match it with the current list of urls,
 * if it matches one it returns the url it matches with, otherwise it throws a
 * new UrlNotFound Exception
 * @param {string} url - the url, without the domain name
 * @private
 */
function matchUrl(url) {
    for(let i = 0; i < urls.length; i++){
        let targetUrl = urls[i];
        if(targetUrl.match(url)) {
            return targetUrl;
        }
    }
    throw {
        'name': 'UrlNotFound',
        'message': `Could not find url: "${url}"`
    };
}
