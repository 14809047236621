/**
 * Home view module.
 */

"use strict";

import * as Gui from '../core/gui';
import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as State from '../core/state';
import * as Signals from '../core/signals';
import * as Debug from '../core/debug';

/**
 * Home view class
 */
export class HomeView {
    constructor() {
        this.viewName = 'HomeView';
        this.listenerIds = [];
        this.loadingTimeout = -1;
        this.actionHandlerIds = [];
        this.listenerIds = [];
    }

    destructor() {
        Signals.removeListeners(this.listenerIds);
    }

    render(event, args) {
        let context = {};
        Gui.hideBackButton();
        Gui.setTitle('');
        Gui.render('home.html', context);
    }
}
