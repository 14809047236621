/**
 * The I8n module handles all translations. It offers a handy function "_" that
 * can quickly be used to translate a string. This function is available in the
 * Global namespace
 * @module i8n
 */

"use strict";

import * as Session from './session';
import * as Debug from './debug';
import * as Nl from '../locale/nl';

/**
 * A mapping for all translations
 * @private
 */
let translations = {};
let actionNames = {};

/**
 * Initialize the translations. Setup global underscore function (window._)
 * pointing to the _ function in this module
 */
export function initTranslations() {
    window._ = _;
    addTranslation('nl', Nl.getTranslations());
}

/**
 * Adds a translation to the translations mapping
 * @param language the language
 * @param translation the translation dictionary
 */
export function addTranslation(language, translation){
    Debug.log("Adding translation for " + language);
    translations[language] = translation;
}

/**
 * Transforms the first character of a given string to lowercase
 * @param {string} input
 * @return {string}
 */
export function deCapitalizeFirstLetter(input) {
    if(input.length === 0){
        return '';
    }
    return input.charAt(0).toLowerCase() + input.slice(1);
}


/**
 * Transforms the first character of a given string to uppercase
 * @param {string} input
 * @return {string}
 */
export function capitalizeFirstLetter(input) {
    if(input.length === 0){
        return '';
    }
    return input.charAt(0).toUpperCase() + input.slice(1);
}

/**
 * Translate a given source string into the target language. The target
 * language is retrieved from the [[Session]]
 * @param source the original English string
 * @returns string the translation if it's available, otherwise it returns
 *                 the original string
 * @global
 */
export function _(source) {
    if(source === '') {
        return '';
    }

    let language = Session.getLanguage();
    let translation;
    if(language === 'en'){
        translation = source;
    } else {
        if(
            translations.hasOwnProperty(language) &&
            translations[language].hasOwnProperty(source)
        ){
            translation = translations[language][source];
        } else {

            let decap = deCapitalizeFirstLetter(source);

            if(
                translations.hasOwnProperty(language) &&
                translations[language].hasOwnProperty(decap)
            ){
                translation = capitalizeFirstLetter(translations[language][decap]);
            } else {
                Debug.log(`Translation for "${source}" not found, for language ${language}`);
                translation = source;
            }
        }
    }
    return translation;
}

export function translate(source) {
    return _(source);
}
