/**
 * MedSwitch view module.
 */

"use strict";

import * as Gui from '../core/gui';
import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as State from '../core/state';
import * as Signals from '../core/signals';
import * as Debug from '../core/debug';
import * as Helpers from '../core/helpers';

/**
 * MedSwitch view class
 */
export class MedSwitchView {
    constructor() {
        this.viewName = 'MedSwitchView';
        this.listenerIds = [];
        this.loadingTimeout = -1;
        this.actionHandlerIds = [];
        this.listenerIds = [];
        this.fromField = null;
        this.toField = null;
        this.data = State.getData();
        this.type = '';
    }

    destructor() {
        Signals.removeListeners(this.listenerIds);
        Actions.removeActionHandlers(this.actionHandlerIds);
    }

    submit() {
        const fromValue = $('select[name=from]').val();
        const toValue = $('select[name=to]').val();
        window.visit('/#/switch-result/' + fromValue + '/' + toValue);
    }

    initToField(items=[]) {
        let options = '<option disabled selected value="">' + Helpers.capitalize(this.type) + ' to switch to</option>';
        for(let option of items) {
            let name = Helpers.capitalize(option);
            if(this.data.hasOwnProperty(option)) {
                name = this.data[option].name;
            }
            options += '<option value="' + option + '">' + name + '</option>';
        }
        $('select[name=to]').html(options);
        $('select[name=to]').before('<label for="med-from">To</label>');
        this.toField = M.FormSelect.init($('select[name=to]'))[0];
    }

    selectFrom(element) {
        let name = $(element).val();
        if(!name) {
            return this.initToField();
        }

        if(this.toField) {
            this.toField.destroy();
            this.toField = null;
        }

        this.initToField(Object.keys(this.data[name].switches));
    }

    render(event, args) {
        this.type = args.type;
        let context = {
            'type': this.type,
        };

        let meds = {};
        for(let med in this.data) {
            if(!this.data.hasOwnProperty(med)) {
                continue;
            }
            if(this.data[med].type.indexOf(this.type) === -1) {
                continue;
            }
            meds[med] = this.data[med];
        }

        context.meds = Helpers.sortObjectByKeys(meds);

        const title = Helpers.capitalize(this.type + 's');

        Gui.setTitle(title);
        Gui.showBackButton();
        Gui.render('medswitch.html', context);

        this.fromField = M.FormSelect.init($('select[name=from]'), {
            dropdownOptions: {
                onCloseEnd: this.selectFrom.bind(this)
            }
        })[0];

        this.initToField();

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                'switch-med', 'switch-med', this.submit.bind(this)
            )
        );

    }
}
