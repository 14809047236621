/**
 * Helpers module.
 * @module helpers
 */

"use strict";

import * as Constants from './constants';

/**
 * Checks the type of a given value
 * @param {any} type - can be 'string', 'number', or 'boolean', 'array'
 *                       or 'object'
 * @param {any} value - the value to check the type of
 * @returns {boolean} whether the type matches
 */
export function checkType(type, value) {
    switch(type){
        case 'string': {
            return typeof value === 'string';
        }
        case 'number': {
            return typeof value === 'number';
        }
        case 'boolean': {
            return typeof value === 'boolean';
        }
        case 'array': {
            return Array.isArray(value);
        }
        case 'object': {
            return typeof value === 'object';
        }
        default: {
            // Unrecognized type
            return false;
        }
    }
}

/**
 * Check whether given variable isn't undefined
 * @param {any} o
 * @returns {boolean}
 */
export function isDefined(o) {
    return typeof o !== 'undefined';
}

/**
 * Checks the nodetype of a given event target
 * @param {Event} event - the event of a click
 * @returns {boolean} whether currentTarget is a form
 */
export function eventIsForm(event) {
    return $(event.currentTarget).is('form');
}

/**
 * Checks the nodetype of a given event target
 * @param {Event} event - the event of a click
 * @returns {boolean} whether currentTarget is a link
 */
export function eventIsLink(event) {
    return $(event.currentTarget).is('a');
}

/**
 * Create a new event
 * @param {string} eventName
 */
export function createNewEvent(eventName = '') {
    let event;

    // Polyfill for IE
    if(typeof(Event) === 'function') {
        event = new Event(eventName);
    } else {
        event = document.createEvent('Event');
        event.initEvent(eventName, true, true);
    }
    return event;
}


/**
 * Pad a string with zeros until it becomes given size
 * @param {string} str
 * @param {number} size - the size
 * @returns {string} the padded string
 */
export function padZero(str, size) {
    str = String(str);
    while (str.length < size) {
        str = "0" + str;
    }
    return str;
}

/**
  * Deep clones all properties except functions and regexes
  * @param {object} obj
  * @returns {object}
  */
export function clone(obj) {
    if (typeof obj === 'function') {
        return obj;
    }
    var result = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
        if(!obj.hasOwnProperty(key)) {
            continue;
        }
        let value = obj[key];
        let type = {}.toString.call(value).slice(8, -1);

        if (type === 'Array' || type === 'Object') {
            result[key] = clone(value);
        } else if (type === 'Date') {
            result[key] = new Date(value.getTime());
        }else {
            result[key] = value;
        }
    }
    return result;
}

/**
 * Returns the minimum of two numbers
 * @param {number} x
 * @param {number} y
 * @returns {number} the minimum
 */
export function minimum(x, y){
    return x < y ? x : y;
}

/**
 * Returns true if the given value is not undefined and not null
 * @param {any} val
 * @returns {boolean}
 */
export function definedNotNull(val){
    return typeof val !== 'undefined' && val !== null;
}

/**
 * Get the value for DOM input element with given name
 * @param {string} name
 * @returns {string | number | string[] | false}
 */
export function inputVal(name) {
    let elem = $('[name=' + name + ']');
    if(elem.length === 0) {
        return false;
    }

    return elem.val();
}

export function arraysContainSame(a, b) {
    a = Array.isArray(a) ? a : [];
    b = Array.isArray(b) ? b : [];
    return a.length === b.length && a.every(el => b.includes(el));
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/**
 * Replace all occurences of "find" to "replace" in given string
 * @param {string} str
 * @param {string} find
 * @param {string} replace
 */
export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

/**
 * Capitalizes given string, ie "name" is changed into "Name"
 * @param {string} str - the string to capitalize
 * @returns {string}
 */
export function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Retrieve data attribute from given DOM element
 * @param {object} elem - the DOM element
 * @param {string} attr - the attribute name, interpreted as 'data-[attr]'
 * @returns {false|string}
 */
export function data(elem, attr) {
    const result = $(elem).attr('data-' + attr);
    if(!checkType('string', result)) {
        return false;
    }
    return result;
}

/**
 * Join the given path parts into one big path without a trailing slash
 * @param {array} parts
 * @returns {string}
 */
export function joinPaths(parts) {
    let path = '';
    let components = [];
    for(let i = 0; i < parts.length; i++) {
        let part = parts[i];
        if(part.length === 0) {
            continue;
        }
        if(part.endsWith('/')) {
            part = part.substring(0, part.length - 1);
        }
        components.push(part);
    }
    return components.join('/');
}

export function arrayContains(needle, haystack) {
    return haystack.indexOf(needle) > -1;
}

/**
 * Check whether a given variable is numeric
 * @returns {boolean}
 */
export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * Render given timestamp as a nice time string
 * @param {number} timestamp
 * @param {number} timeZoneOffset
 * @param {boolean} use24Hours
 * @returns {string}
 */
export function formatTime(timestamp, timeZoneOffset=0, use24Hours=false) {
    const btzo = new Date().getTimezoneOffset() * 60000;
    const date = new Date(timestamp + btzo + (timeZoneOffset * 60000));
    const hours24 = date.getHours();
    let minutes = date.getMinutes().toString();
    let ampm = 'AM';
    let hours = hours24;

    if (hours24 > 12) {
        hours = hours24 - 12;
        ampm = 'PM';
    } else if (hours24 === 12) {
        hours = 12;
        ampm = 'PM';
    } else if (hours24 === 0) {
        hours = 12;
    }

    if(isNaN(hours) || !isNumeric(minutes)) {
        return '';
    }

    if(minutes.length < 2) {
        minutes = '0' + minutes;
    }


    if(!use24Hours) {
        return hours + ':' + minutes + ' <small class="am-pm">' + ampm + '</small>';
    }
    return hours24 + ':' + minutes;
}

/**
 * Render given timestamp as a nice datetime string
 * @param {number} timestamp
 * @param {number} timeZoneOffset
 * @param {boolean} use24Hours
 * @returns {string}
 */
export function formatDateTime(timestamp, timeZoneOffset=0, use24Hours=false) {
    const btzo = new Date().getTimezoneOffset() * 60000;
    const date = new Date(timestamp - btzo + (timeZoneOffset * 60000));
    return (
        date.getFullYear() + '-' +
        (date.getMonth() + 1).toString() + '-' +
        date.getDate() + ' ' +
        formatTime(timestamp, timeZoneOffset, use24Hours)
    );
}

/**
 * Reverse a given string
 * @param {string} str
 * @returns {string}
 */
export function reverseString(str) {
    return str.split("").reverse().join("");
}

/**
 * Convert a string from camel case to snake case
 * @param {string} str
 * @returns {string}
 */
export function camelToSnakeCase(str) {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

/**
 * Round given number to n decimals
 * @param {number} input - the input number
 * @param {number} decimals - amount of decimals to round to
 */
export function round(input, decimals) {
    return Math.round(input * (Math.pow(10, decimals))) / Math.pow(10, decimals);
}


/**
 * @returns {number} amount of keys in object
 */
export function keyCount(obj) {
    return Object.keys(obj).length;
}

export function sortObjectByKeys(unordered) {
    return Object.keys(unordered).sort().reduce(
          (obj, key) => {
              obj[key] = unordered[key];
              return obj;
            },
          {}
    );
}

export function sortObjectByValue(unordered) {
    let flatList = [];

    for (let key in unordered) {
        flatList.push([key, unordered[key]]);
    }

    flatList.sort(function(a, b) {
        if(a[1] < b[1]) {
            return -1;
        }
        return 1;
    });

    let sorted = {};
    flatList.forEach(function(item){
        sorted[item[0]]=item[1];
    });

    return sorted;
}
