/**
 * Events module.
 * The Events module is the main event system for handling link clicks and
 * form submits.
 * @module events
 */

"use strict";

import {createNewEvent} from './helpers';
import {log} from './debug';
import * as Actions from './actions';
import * as Routing from '../routing';
import * as Gui from './gui';
import * as Helpers from './helpers';
import * as State from './state';
import * as Signals from './signals';

export function initEvents() {
    log('Initializing events');

    $(document).on('click', 'button.action, a.action:not(.no-link)', triggerAction);
    $(document).on('change', 'select.action', triggerAction);
    $(document).on('click', 'button.back, a.back', backButtonClick);
    $(document).on('click', 'a.scroll-to-top', scrollToTop);
    $('ul#slide-out a.close').click((ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        Gui.closeMenu();
    });
    $(document).on(
        'click',
        "a:not([rel='external']):not(.external, .back, .close)",
        linkClick
    );
    $(document).on('submit', 'form', formSubmit);
    $(document).on('click', 'a.menu', menuClick);
    $(document).on('click', 'a.no-link', (el) => {
        el.preventDefault();
        el.stopPropagation();
    });

    $(document).on('click', 'a.close-message', (event) => {
        event.preventDefault();
        event.stopPropagation();
        $(event.currentTarget).parent().fadeOut();
    });

    $(window).on('popstate', function(event){
        Routing.visit(
            window.location.pathname + window.location.hash,
            createNewEvent('popstate'),
            false,
            false
        );
    });

    $(window).resize(resizeHandler);

    // Listen to global device status signals
    initGlobalSignals();
}

/**
 * Initialize the global signals for device status/mode changes.
 */
function initGlobalSignals() {
}

/**
 * This handler is called when the window is resized
 */
function resizeHandler() {
}

/**
 * Pass the given event to the actions module for triggering
 * the connected action handlers.
 * @param {Event} event - the DOM event
 */
function triggerAction(event){
    const target = $(event.currentTarget);
    if(target.hasClass('action')){
        event.preventDefault();
        event.stopPropagation();
        Actions.trigger(target);
    }
}

/**
 * General event handler for link clicks. This is called on all links ("a tags"
 * that don't have rel="external" or class "external". It automatically
 * prevents the default handler from taking action, and stops propagation (so
 * events don't bubble op the DOM). After this, it calls the [[Routing.visit]]
 * function, so it can take appropriate action on this event.  @param {Event}
 * event - the Event object
 */
function linkClick(event) {
    const target = $(event.currentTarget);

    var href = target.attr('href');

    if ($('ul#slide-out').has(target).length > 0){
        Gui.closeMenu();
    }

    // Clicked on an anchor? Don't do anything
    if(href.length > 0 && href[0] === '#'){
        return;
    }

    event.preventDefault();
    event.stopPropagation();

    Routing.visit(href, event);
}

/**
 * This function is triggered whenever the menu button is clicked. It toggles the menu.
 * @param {Event} event - the Event object
 */
function menuClick(event) {
    event.preventDefault();
    event.stopPropagation();
    $('div.menu').toggle();
}

/**
 * Back button event handler
 */
function backButtonClick(event) {
    event.stopPropagation();
    event.preventDefault();
    history.back();
}

/**
 * General event handler for form submits. This is called on all forms that
 * trigger the submit event. It prevents the default handler from taking
 * action, but does not stop propagation. It passes the event through to
 * [[Routing.visit]] so it can take appropriate action on this event.
 * @param {Event} event - the Event object
 */
function formSubmit(event) {
    event.preventDefault();
    const target = $(event.currentTarget);
    if(target.hasClass('action')){
        event.preventDefault();
        event.stopPropagation();
        Actions.trigger(target);
    } else {
        Routing.visit($(event.currentTarget).attr('action'), event);
    }
}

function scrollToTop(event) {
    event.preventDefault();
    event.stopPropagation();
    window.scrollTo(0,0);
}

