/**
 * Info view module.
 */

"use strict";

import * as Gui from '../core/gui';
import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as State from '../core/state';
import * as Signals from '../core/signals';
import * as Debug from '../core/debug';
import * as Helpers from '../core/helpers';

/**
 * Info view class
 */
export class InfoView {
    constructor() {
        this.viewName = 'InfoView';
        this.listenerIds = [];
        this.loadingTimeout = -1;
        this.actionHandlerIds = [];
        this.listenerIds = [];
    }

    destructor() {
        Signals.removeListeners(this.listenerIds);
    }

    render(event, args) {
        Gui.showBackButton();
        Gui.setTitle('Info');
        let context = {};
        Gui.render('info.html', context);
    }

}
