/**
 * API module. This module handles all the API calls
 */

"use strict";

import * as Helpers from './helpers';
import * as Debug from './debug';
import * as Session from './session';
import * as Dah from './dah';

/**
 * @param {string} lastHash
 * @returns {Promise}
 */
export function getData(lastHash=false) {
    Debug.log('Retrieve medication data');

    return new Promise((resolve, reject) => {
        const oReq = new XMLHttpRequest();
        // TODO: Retrieve on external server
        oReq.open('GET', 'data/data.json.gz', true);
        oReq.responseType = 'arraybuffer';

        oReq.onload = function(oEvent) {
            const arrayBuffer = oReq.response;
            const byteArray = new Uint8Array(arrayBuffer);
            const data = pako.inflate(byteArray, {to: 'string'});
            return resolve(JSON.parse(data));
        };

        oReq.send();
    });
}
