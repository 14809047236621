/**
 * Main module.
 * @module main
 */

"use strict";

import * as Actions from './core/actions';
import * as Debug from './core/debug';
import * as Events from './core/events';
import * as Gui from './core/gui';
import * as Helpers from './core/helpers';
import * as I8n from './core/i8n';
import * as Routing from './routing';
import * as Session from './core/session';
import * as Signals from './core/signals';
import * as State from './core/state';
import * as Messages from './core/messages';

window.DEBUG = false;

let initialized = false;

Gui.initGui();
Gui.render('loading.html');
Session.initSession();
I8n.initTranslations();
Events.initEvents();
Actions.initActions();
Routing.initRouting();

function ready() {
    if(typeof cordova !== 'undefined') {
        window.open = cordova.InAppBrowser.open;
    } else {
        window.DEBUG = true;
    }

    Debug.log('Initializing');
    Debug.log('============');

    const initState = () => {
        initialized = true;

        const path = window.location.pathname + window.location.hash;
        if(path === '/' || path === '/#/') {
            Routing.visit('/');
        } else {
            Routing.visit(path);
        }
    };

    State.initState().then(initState).catch((err) => {
        // TODO error handling for when initializing state does not work
        Debug.log(err);
    });
}



$(document).ready(() => {
    if(window.hasOwnProperty("cordova")){
        document.addEventListener("deviceready", ready, false);
    } else {
        ready();
    }
});
