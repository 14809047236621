/**
 * Debug view module.
 */

"use strict";

import * as Gui from '../core/gui';
import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as State from '../core/state';
import * as Signals from '../core/signals';
import * as Debug from '../core/debug';
import * as Helpers from '../core/helpers';

/**
 * Debug view class
 */
export class DebugView {
    constructor() {
        this.viewName = 'DebugView';
        this.listenerIds = [];
        this.loadingTimeout = -1;
        this.actionHandlerIds = [];
        this.listenerIds = [];
        this.data = State.getData();
    }

    destructor() {
        Signals.removeListeners(this.listenerIds);
    }

    render(event, args) {
        Gui.showBackButton();
        Gui.setTitle('Debug');

        let output = '';

        output += 'Check if all switches are reachable\n';
        output += '===================================\n';
        let reachable = true;

        for(let medName in this.data) {
            if(!this.data.hasOwnProperty(medName)) {
                continue;
            }
            let med = this.data[medName];
            output += 'Checking ' + medName + '\n';
            output += '- Amount of switches: ' + Object.keys(med.switches).length + '\n';
            for(let sw in med.switches) {
                if(!med.switches.hasOwnProperty(sw)) {
                    continue;
                }
                if(!this.data.hasOwnProperty(sw)) {
                    output += 'ERROR: ' + sw + ' not found (coming from ' + medName + ')\n';
                    reachable = false;
                }
            }
            output += '\n\n';
        }

        output += JSON.stringify(this.data, null, 2);

        Gui.render('debug.html', {output: output});
    }

}
