/**
 * BenzoConversionResult view module.
 */

"use strict";

import * as Gui from '../core/gui';
import * as Constants from '../core/constants';
import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as Signals from '../core/signals';
import * as Debug from '../core/debug';
import * as Helpers from '../core/helpers';

/**
 * BenzoConversionResult view class
 */
export class BenzoConversionResultView {
    constructor() {
        this.viewName = 'BenzoConversionResultView';
        this.listenerIds = [];
        this.loadingTimeout = -1;
        this.actionHandlerIds = [];
        this.listenerIds = [];
    }

    getBenzoConversion(input) {
        let total = 0;
        let output = '';
        for(let item of input['from']) {
            const from = Helpers.capitalize(item.benzo);
            const dosage = item.dosage;
            const key = from + '-' + input.conversionDrug;
            if(!Constants.BENZO_TABLE.hasOwnProperty(key)) {
                continue;
            }

            const conversion = Constants.BENZO_TABLE[key];
            if(!Helpers.isNumeric(dosage)) {
                continue;
            }
            let result = Helpers.round(dosage * parseFloat(conversion), 1);
            output += '<p>' + from + ' in dosage ' + dosage + ' mg/day corresponds to ' + conversion + ' * ' + dosage + ' = ' + result + ' mg ' + input.conversionDrug + ' per day</p><br/>';
            total = total + result;
        }

        if(total === 0)  {
            // TODO
            alert("You haven't selected any benzodiazepines.");
        }

        output += '<p class="total">Total corresponding dosage ' + input.conversionDrug + ' is ' + total + ' mg per day</p>';

        if(total > Constants.MAXIMA[input.conversionDrug]) {
            output += '<p class="warning"><strong>Warning: </strong> the standard maximum dosage of ' + Constants.MAXIMA[input.conversionDrug] + ' ' + input.conversionDrug + ' per day will be exceeded.</p>';
        }

        return output;
    }

    destructor() {
        Signals.removeListeners(this.listenerIds);
        Actions.removeActionHandlers(this.actionHandlerIds);
    }

    render(event, args, input) {
        let context = {
            conversion: this.getBenzoConversion(input),
            conversionDrug: input.conversionDrug,
        };

        Gui.showBackButton();
        Gui.render('benzoconversionresult.html', context);
        Gui.setTitle('Conversion');
    }

}
