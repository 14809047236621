/**
 * MedSwitchResult view module.
 */

"use strict";

import * as Gui from '../core/gui';
import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as State from '../core/state';
import * as Signals from '../core/signals';
import * as Debug from '../core/debug';
import * as Helpers from '../core/helpers';
import * as Session from '../core/session';

/**
 * MedSwitchResult view class
 */
export class MedSwitchResultView {
    constructor() {
        this.viewName = 'MedSwitchResultView';
        this.listenerIds = [];
        this.loadingTimeout = -1;
        this.actionHandlerIds = [];
        this.listenerIds = [];
        this.data = State.getData();
        this.actionHandlerIds = [];
        this.name = '';
    }

    destructor() {
        Signals.removeListeners(this.listenerIds);
        Actions.removeActionHandlers(this.actionHandlerIds);
    }

    addFavorite() {
        Session.addFavorite(this.name);
        this.showPage();
    }

    removeFavorite() {
        Session.removeFavorite();
        this.showPage();
    }

    showPage() {
        let context = {
            from: this.from,
            to: this.to,
            medFrom: this.data[this.from],
            medTo: this.data[this.to],
            isFavorite: Session.isFavorite(),
        };

        let fromName = Helpers.capitalize(this.from.replace('_', ' '));
        let toName = Helpers.capitalize(this.to.replace('_', ' '));
        this.name = fromName + ' -> ' + toName;

        if(this.data.hasOwnProperty(this.from)) {
            fromName = this.data[this.from].name;
        } else {
            Debug.log('FROM not found');
            Debug.log(this.from);
        }

        if(this.data.hasOwnProperty(this.to)) {
            toName = this.data[this.to].name;
        } else {
            Debug.log('TO not found')
            Debug.log(this.to);
        }

        Gui.setSwitchTitle(
            fromName,
            toName,
        );
        Gui.showBackButton();
        Debug.log(context);
        Gui.render('medswitchresult.html', context);
    }

    render(event, args) {
        this.from = args.from;
        this.to = args.to;

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                'add-favorite', 'add-favorite', this.addFavorite.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                'remove-favorite', 'remove-favorite', this.removeFavorite.bind(this)
            )
        );

        this.showPage();

        // TODO: Remove more info if empty
    }

}
