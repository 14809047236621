"use strict";

let translations = {
    // ../src/templates/time.html,
    '24H or AM/PM': '24H of AM/PM',

    // ../src/templates/chart.html,
    '48h chart': '48H grafiek',

    // ../src/templates/log.html,
    'Actions': 'Acties',

    // ../src/templates/parameter.html,src/templates/chart.html,src/templates/setting.html,
    'Actual value': 'Huidige waarde',

    // ../src/templates/user.html,
    'Add new user': 'Voeg nieuwe gebruiker toe',

    // ../src/templates/related-add.html,
    'Add related objects': 'Voeg gerelateerde objecten toe',

    // Action title for add-to-clfb,
    'Add to CLFB': 'Voeg toe aan CLFB',

    // IQ4 Text for parameter CLEANFLEX_AIR_PRESSURE,IQ4 Text for parameter BLOWFLEX_AIR_PRESSURE,
    'Air pressure': 'Luchtdruk',

    // IQ4 Text for parameter CLEANFLEX_TRIGGER_LEVEL,IQ4 Text for parameter BLOWFLEX_TRIGGER_LEVEL,
    'Air pressure trigger level high voltage': 'Luchtdruk trigger niveau hoog spanning aan',

    // IQ4 Text for parameter GATEWAY_ALARM_SETPOINT,IQ4 Text for parameter GATEWAY_EX_ALARM_SETPOINT,IQ4 Text for parameter SEGMENT_4_QID_ALARM_SETPOINT,
    'Alarm level, measured value over maximum': 'Alarm niveau, gemeten waarde over het maximum',

    // IQ4 Text for parameter GATEWAY_ALARM_MINIMUM,IQ4 Text for parameter GATEWAY_EX_ALARM_MINIMUM,IQ4 Text for parameter SEGMENT_4_QID_ALARM_MINIMUM,
    'Alarm level, measured value under minimum': 'Alarm niveau, gemeten waarde onder minimum',

    // ../src/templates/log.html,
    'Alarms': 'Alarmen',

    // IQ4 Text for parameter GATEWAY_4_QID_TEMPERATURE_SENSOR_VALUE,
    'Ambient temperature': 'Omgevingstemperatuur',

    // IQ4 Text for parameter GATEWAY_4_QID_TEMPERATURE_SENSOR_AVAILABLE,
    'Ambient temperature sensor available': 'Omgevingstemperatuursensor beschikbaar',

    // IQ4 Text for parameter CM_TINY_ARC_DETECT_COUNTER,IQ4 Text for parameter IQ_COM_ARC_DETECT_COUNTER,
    'Arcs per minute counter': 'Overslagen per minuut teller',

    // ../src/templates/user-delete.html,
    'Are you sure you want to delete the user': 'Weet u zeker dat u de gebruiker wilt verwijderen?',

    // IQ4 Text for parameter GATEWAY_AVAILABLE_SEGMENTS,IQ4 Text for parameter GATEWAY_EX_AVAILABLE_SEGMENTS,
    'Available Sensor segments': 'Beschikbare Sensorsegmenten',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Available updates': 'Beschikbare updates',

    // ../src/templates/settings-updates.html,
    'Available version': 'Beschikbare versie',

    // ../src/templates/device.html,
    'Back to top': 'Terug naar Boven',

    // Action title for calibrate,
    'Calibrate bar': 'Kalibreer staaf',

    // ../src/templates/parameter.html,src/templates/setting.html,
    'Cancel': 'Annuleer',

    // IQ4 Text for parameter CMME_CHARGE_SINGLE_SHOT_TIME,IQ4 Text for parameter IQ_COM_CHARGE_SINGLE_SHOT_TIME,
    'Charge pulse single shot': 'Oplaadpuls single shot',

    // ../src/templates/settings-install-updates-error.html,src/templates/settings.html,
    'Check available updates': 'Controleer op beschikbare updates',

    // IQ4 Text for parameter PERFORMAX_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter EASION_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter PERFORMAX_EX_CLEAN_BAR_WARNING_LEVEL,IQ4 Text for parameter THUNDERION2_CLEAN_BAR_WARNING_LEVEL,
    'Clean bar warning level': 'Reinigen staaf waarschuwingsniveau',

    // Action title for clear,
    'Clear action log': 'Wis actielogboek',

    // ../src/templates/connecting.html,
    'Connecting to websocket, please wait.': 'Verbinden met websocket, even geduld.',

    // ../src/templates/log-table.html,
    'Could not retrieve log': 'Kon logboek niet ophalen',

    // ../src/../src/views/setting.js,
    'Could not save the new settings': 'Kon de nieuwe instellingen niet opslaan',

    // ../src/../src/views/parameter.js,
    'Could not save the new value': 'Kon de nieuwe waarde niet opslaan',

    // Action title for create-related,
    'Create related object': 'Maak gerelateerd object',

    // IQ4 Text for parameter CM_TINY_SETPOINT_CURRENT_MNGR4,IQ4 Text for parameter IQ_COM_SETPOINT_CURRENT_60KV_MNGR4,
    'Current Control current setpoint': 'Stroomregeling instelpunt',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_LIMIT_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_LIMIT_60KV_MNGR4,
    'Current Control output voltage limit': 'Spanningslimiet voor stroomregeling',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'Current system time': 'Huidige systeemtijd',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Current version': 'Huidige versie',

    // IQ4 Text for parameter CMME_CYCLE_OK_HIGH_DELAY_THRESHOLD,
    'Cycle OK high delay': 'Cycle OK vertraging',

    // IQ4 Text for parameter CMME_CYCLE_OK_LOW_LEVEL_THRESHOLD_MNGR4,
    'Cycle OK low level': 'Cycle OK laag niveau',

    // ../src/templates/time.html,src/templates/log.html,
    'Date': 'Datum',

    // ../src/templates/parameter.html,src/templates/chart.html,
    'Default value': 'Standaard waarde',

    // ../src/templates/user.html,
    'Delete': 'Verwijderen',

    // Action title for delete-related,
    'Delete related object': 'Verwijder gerelateerd object',

    // ../src/templates/user-delete.html,
    'Delete user': 'Verwijder gebruiker',

    // IQ4 Text for setting CLFB_DERIVATIVEGAIN,
    'Derivative Gain': 'Derivatieve Gain',

    // ../src/templates/debug-report.html,
    'Description': 'Beschrijving',

    // IQ4 Text for parameter CM_TINY_DEVICE_NAME,IQ4 Text for parameter CMME_DEVICE_NAME,IQ4 Text for parameter IQ_COM_DEVICE_NAME,IQ4 Text for parameter GATEWAY_DEVICE_NAME,IQ4 Text for parameter GATEWAY_EX_DEVICE_NAME,IQ4 Text for parameter GATEWAY_4_QID_DEVICE_NAME,IQ4 Text for parameter SEGMENT_4_QID_DEVICE_NAME,IQ4 Text for parameter PERFORMAX_DEVICE_NAME,IQ4 Text for parameter EASION_DEVICE_NAME,IQ4 Text for parameter PERFORMAX_EX_DEVICE_NAME,IQ4 Text for parameter THUNDERION2_DEVICE_NAME,IQ4 Text for parameter VICINION_DEVICE_NAME,IQ4 Text for parameter CLEANFLEX_DEVICE_NAME,IQ4 Text for parameter BLOWFLEX_DEVICE_NAME,
    'Device name': 'Apparaat naam',

    // IQ4 Text for parameter CM_TINY_DEVICE_TYPE,IQ4 Text for parameter CMME_DEVICE_TYPE,IQ4 Text for parameter IQ_COM_DEVICE_TYPE,IQ4 Text for parameter GATEWAY_DEVICE_TYPE,IQ4 Text for parameter GATEWAY_EX_DEVICE_TYPE,IQ4 Text for parameter PERFORMAX_DEVICE_TYPE,IQ4 Text for parameter EASION_DEVICE_TYPE,IQ4 Text for parameter PERFORMAX_EX_DEVICE_TYPE,IQ4 Text for parameter THUNDERION2_DEVICE_TYPE,IQ4 Text for parameter VICINION_DEVICE_TYPE,IQ4 Text for parameter CLEANFLEX_DEVICE_TYPE,IQ4 Text for parameter BLOWFLEX_DEVICE_TYPE,
    'Device type': 'Apparaat type',

    // IQ4 Text for parameter CM_TINY_DEVICE_TYPE_VERSION,IQ4 Text for parameter CMME_DEVICE_TYPE_VERSION,IQ4 Text for parameter IQ_COM_DEVICE_TYPE_VERSION,IQ4 Text for parameter PERFORMAX_DEVICE_TYPE_VERSION,IQ4 Text for parameter EASION_DEVICE_TYPE_VERSION,IQ4 Text for parameter PERFORMAX_EX_DEVICE_TYPE_VERSION,IQ4 Text for parameter THUNDERION2_DEVICE_TYPE_VERSION,
    'Device type version': 'Apparaat type versie',

    // Action title for disable,
    'Disable': 'Deactiveer',

    // Action title for remove,
    'Disconnect': 'Loskoppelen',

    // IQ4 Text for parameter SEGMENT_4_QID_DISTANCE_SENSOR_AVAILABLE,
    'Distance sensor available': 'Afstandssensor beschikbaar',

    // IQ4 Text for parameter SEGMENT_4_QID_DISTANCE_SENSOR_MALFUNCTION,
    'Distance sensor malfunction': 'Afstandssensor storing',

    // IQ4 Text for parameter SEGMENT_4_QID_MOUNTING_DISTANCE_OVERRIDE,
    'Distance sensor override': 'Afstandssensor override',

    // ../src/../src/core/gui.js,
    'Done': 'Voltooid',

    // ../src/templates/user.html,
    'Edit': 'Bewerken',

    // ../src/templates/macros.html,
    'Edit parameter': 'Parameter bewerken',

    // Action title for edit-related,
    'Edit related objects': 'Bewerk gerelateerde objecten',

    // ../src/templates/macros.html,src/templates/macros.html,
    'Edit setting': 'Bewerk instelling',

    // ../src/templates/user-edit.html,
    'Edit user': 'Gebruiker aanpassen',

    // Action title for enable,
    'Enable': 'Activeer',

    // IQ4 Text for parameter GATEWAY_ENABLED_SEGMENTS,IQ4 Text for parameter GATEWAY_EX_ENABLED_SEGMENTS,
    'Enabled Sensor segments': 'Geactiveerde sensorsegmenten',

    // ../src/../src/views/time.js,
    'Error changing the system time': 'Fout bij het wijzigen van de systeemtijd',

    // ../src/../src/views/user.js,
    'Error deleting user': 'Fout bij verwijderen gebruiker',

    // ../src/templates/settings-install-updates-error.html,
    'Error installing updates': 'Fout bij het installeren van updates',

    // ../src/../src/views/user.js,
    'Error: failed to create user': 'Fout: aanmaken gebruiker mislukt',

    // ../src/../src/views/user.js,
    'Error: failed to delete user': 'Fout: Verwijderen gebruiker mislukt',

    // ../src/../src/views/user.js,
    'Error: failed to edit user': 'Fout: bewerken van gebruiker mislukt',

    // ../src/../src/views/user.js,
    'Error: failed to update user': 'Fout: bijwerken gebruiker mislukt',

    // ../src/templates/log.html,
    'Events': 'Activiteiten',

    // Action title for export,
    'Export configuration': 'Exporteer configuratie',

    // ../src/templates/failed-to-connect.html,
    'Failed to connect to the websocket, please check your settings.': 'Kan geen verbinding maken met de websocket, controleer je instellingen.',

    // ../src/../src/views/login.js,
    'Failed to reset the password. Please check the username and recovery key.': 'Wachtwoord resetten mislukt. Controleer de gebruikersnaam en herstelsleutel.',

    // IQ4 Text for parameter CM_TINY_FIRMWARE_VERSION,IQ4 Text for parameter CMME_FIRMWARE_VERSION,IQ4 Text for parameter IQ_COM_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION,IQ4 Text for parameter GATEWAY_4_QID_FIRMWARE_VERSION,IQ4 Text for parameter SEGMENT_4_QID_FIRMWARE_VERSION,IQ4 Text for parameter PERFORMAX_FIRMWARE_VERSION,IQ4 Text for parameter EASION_FIRMWARE_VERSION,IQ4 Text for parameter PERFORMAX_EX_FIRMWARE_VERSION,IQ4 Text for parameter THUNDERION2_FIRMWARE_VERSION,IQ4 Text for parameter VICINION_FIRMWARE_VERSION,IQ4 Text for parameter CLEANFLEX_FIRMWARE_VERSION,IQ4 Text for parameter BLOWFLEX_FIRMWARE_VERSION,
    'Firmware version': 'Firmware versie',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG1,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG1,
    'Firmware version seg1': 'Firmware versie seg1',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG10,
    'Firmware version seg10': 'Firmware versie seg10',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG11,
    'Firmware version seg11': 'Firmware versie seg11',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG12,
    'Firmware version seg12': 'Firmware versie seg12',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG13,
    'Firmware version seg13': 'Firmware versie seg13',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG14,
    'Firmware version seg14': 'Firmware versie seg14',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG15,
    'Firmware version seg15': 'Firmware versie seg15',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG16,
    'Firmware version seg16': 'Firmware versie seg16',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG2,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG2,
    'Firmware version seg2': 'Firmware versie seg2',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG3,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG3,
    'Firmware version seg3': 'Firmware versie seg3',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG4,IQ4 Text for parameter GATEWAY_EX_FIRMWARE_VERSION_SEG4,
    'Firmware version seg4': 'Firmware versie seg4',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG5,
    'Firmware version seg5': 'Firmware versie seg5',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG6,
    'Firmware version seg6': 'Firmware versie seg6',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG7,
    'Firmware version seg7': 'Firmware versie seg7',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG8,
    'Firmware version seg8': 'Firmware versie seg8',

    // IQ4 Text for parameter GATEWAY_FIRMWARE_VERSION_SEG9,
    'Firmware version seg9': 'Firmware versie seg9',

    // ../src/templates/settings-updates.html,
    'Hide release notes': 'Verberg release-notities',

    // ../src/../src/views/chart.js,
    'Hide table': 'Tabel verbergen',

    // IQ4 Text for parameter CM_TINY_CURRENT_MON_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_MNGR4,IQ4 Text for parameter CMME_CURRENT_MON_MNGR4,
    'High voltage output current': 'Hoogspanning uitgangsstroom',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_LIMIT_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_LIMIT_60KV_MNGR4,IQ4 Text for parameter CMME_OUTPUT_CURRENT_LIMIT_MNGR4,
    'High voltage output current limit': 'Hoogspanning uitgangsstroom limiet',

    // IQ4 Text for parameter CM_TINY_OUTPUT_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_OUTPUT_CURRENT_PEAK_MNGR4,
    'High voltage output current peak per cycle': 'Hoogspanning uitgangsstroom piek per cyclus',

    // IQ4 Text for parameter THUNDERION2_HV_OUTPUT_FREQUENCY,IQ4 Text for parameter VICINION_HV_OUTPUT_FREQUENCY,
    'High voltage output frequency': 'Uitgangsfrequentie hoogspanning',

    // IQ4 Text for parameter CM_TINY_VSENSE_MON_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_MNGR4,IQ4 Text for parameter VICINION_VSENSE_MON,IQ4 Text for parameter CMME_VSENSE_MON_MNGR4,
    'High voltage output voltage': 'Uitgangsspanning hoogspanning',

    // IQ4 Text for parameter CM_TINY_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter CMME_HV_OVERLOAD_LEVEL_ALARM,IQ4 Text for parameter IQ_COM_HV_OVERLOAD_LEVEL_ALARM,
    'High voltage overload alarm level': 'Alarm niveau overbelasting van hoogspanning',

    // IQ4 Text for parameter CM_TINY_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter CMME_HV_OVERLOAD_LEVEL_WARNING,IQ4 Text for parameter IQ_COM_HV_OVERLOAD_LEVEL_WARNING,
    'High voltage overload warning level': 'Waarschuwingsniveau voor overbelasting hoogspanning',

    // Action title for identify,
    'Identify': 'Identificeren',

    // ../src/templates/log.html,
    'Info': 'Informatie',

    // IQ4 Text for setting CLFB_INPUTSEGMENTS,
    'Input Segments': 'Invoersegmenten',

    // ../src/templates/settings-updates.html,
    'Install all updates': 'Installeer alle updates',

    // IQ4 Text for setting CLFB_INTEGRALDEADBAND,
    'Integral Deadband': 'Integrale Deadband',

    // IQ4 Text for setting CLFB_INTEGRALGAIN,
    'Integral Gain': 'Integraal Gain',

    // IQ4 Text for setting CLFB_INTEGRALMAX,
    'Integral Maximum': 'Integraal maximum',

    // IQ4 Text for setting CLFB_INTEGRALMIN,
    'Integral Minimum': 'Integraal minimum',

    // IQ4 Text for parameter CM_TINY_INTERNAL_TEMP,IQ4 Text for parameter CMME_INTERNAL_TEMP,IQ4 Text for parameter PERFORMAX_INTERNAL_TEMPERATURE,IQ4 Text for parameter QID_BAR_INTERNAL_TEMPERATURE,IQ4 Text for parameter PERFORMAX_EX_INTERNAL_TEMPERATURE,IQ4 Text for parameter THUNDERION2_INTERNAL_TEMPERATURE,IQ4 Text for parameter VICINION_INTERNAL_TEMPERATURE,
    'Internal temperature': 'Interne temperatuur',

    // ../src/templates/macros.html,
    'Internal use': 'Intern gebruik',

    // ../src/../src/views/login.js,
    'Invalid login, please try again': 'Ongeldige login, probeer het opnieuw',

    // ../src/../src/views/parameter.js,
    'Invalid parameter': 'Ongeldige parameter',

    // ../src/../src/views/setting.js,
    'Invalid setting': 'Ongeldige instelling',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'Ionisation balace offset': 'Ionisatie balans offset',

    // IQ4 Text for parameter PERFORMAX_BALANCE_OFFSET,IQ4 Text for parameter EASION_BALANCE_OFFSET,IQ4 Text for parameter PERFORMAX_EX_BALANCE_OFFSET,IQ4 Text for parameter THUNDERION2_HV_BALANCE_OFFSET,
    'Ionisation balance offset': 'Ionisatie balans offset',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_NEG,IQ4 Text for parameter EASION_IONIZATION_CURRENT_NEG,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_CURRENT_NEG,IQ4 Text for parameter THUNDERION2_CURRENT_MON_NEG_MNGR4,
    'Ionisation current negative emitters': 'Ionisatiestroom negatieve emitters',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_CURRENT_POS,IQ4 Text for parameter EASION_IONIZATION_CURRENT_POS,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_CURRENT_POS,IQ4 Text for parameter THUNDERION2_CURRENT_MON_POS_MNGR4,
    'Ionisation current positive emitters': 'Ionisatiestroom positieve emitters',

    // IQ4 Text for parameter PERFORMAX_IONIZATION_EFFICIENCY,IQ4 Text for parameter EASION_IONIZATION_EFFICIENCY,IQ4 Text for parameter PERFORMAX_EX_IONIZATION_EFFICIENCY,IQ4 Text for parameter THUNDERION2_BAR_EFFICIENCY,
    'Ionisation efficiency': 'Ionisatie efficiëntie',

    // IQ4 Text for parameter CM_TINY_LAST_ALARM,IQ4 Text for parameter CMME_LAST_ALARM,IQ4 Text for parameter IQ_COM_LAST_ALARM,IQ4 Text for parameter GATEWAY_LAST_ALARM,IQ4 Text for parameter GATEWAY_EX_LAST_ALARM,IQ4 Text for parameter PERFORMAX_LAST_ALARM,IQ4 Text for parameter EASION_LAST_ALARM,IQ4 Text for parameter PERFORMAX_EX_LAST_ALARM,IQ4 Text for parameter THUNDERION2_LAST_ALARM,IQ4 Text for parameter VICINION_LAST_ALARM,IQ4 Text for parameter CLEANFLEX_LAST_ALARM,IQ4 Text for parameter BLOWFLEX_LAST_ALARM,
    'Last alarm date-time': 'Datum-tijd laatste alarm',

    // IQ4 Text for parameter SEGMENT_4_QID_LAST_CALIBRATION_DATE,IQ4 Text for parameter PERFORMAX_LAST_CALIBRATION_DATE,IQ4 Text for parameter EASION_LAST_CALIBRATION_DATE,IQ4 Text for parameter PERFORMAX_EX_LAST_CALIBRATION_DATE,
    'Last calibration date': 'Laatste kalibratiedatum',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG1,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG1,
    'Last calibration date seg1': 'Laatste kalibratiedatum seg1',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG10,
    'Last calibration date seg10': 'Laatste kalibratiedatum seg10',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG11,
    'Last calibration date seg11': 'Laatste kalibratiedatum seg11',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG12,
    'Last calibration date seg12': 'Laatste kalibratiedatum seg12',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG13,
    'Last calibration date seg13': 'Laatste kalibratiedatum seg13',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG14,
    'Last calibration date seg14': 'Laatste kalibratiedatum seg14',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG15,
    'Last calibration date seg15': 'Laatste kalibratiedatum seg15',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG16,
    'Last calibration date seg16': 'Laatste kalibratiedatum seg16',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG2,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG2,
    'Last calibration date seg2': 'Laatste kalibratiedatum seg2',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG3,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG3,
    'Last calibration date seg3': 'Laatste kalibratiedatum seg3',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG4,IQ4 Text for parameter GATEWAY_EX_LAST_CAL_DATE_SEG4,
    'Last calibration date seg4': 'Laatste kalibratiedatum seg4',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG5,
    'Last calibration date seg5': 'Laatste kalibratiedatum seg5',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG6,
    'Last calibration date seg6': 'Laatste kalibratiedatum seg6',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG7,
    'Last calibration date seg7': 'Laatste kalibratiedatum seg7',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG8,
    'Last calibration date seg8': 'Laatste kalibratiedatum seg8',

    // IQ4 Text for parameter GATEWAY_LAST_CAL_DATE_SEG9,
    'Last calibration date seg9': 'Laatste kalibratiedatum seg9',

    // IQ4 Text for parameter PERFORMAX_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter EASION_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter PERFORMAX_EX_LAST_CLEAN_BAR_WARNING,IQ4 Text for parameter THUNDERION2_LAST_CLEAN_BAR_WARNING,
    'Last clean bar warning date-time': 'Laatste waarschuwingsdatum staaf reinigen',

    // IQ4 Text for parameter CM_TINY_LAST_WARNING,IQ4 Text for parameter CMME_LAST_WARNING,IQ4 Text for parameter IQ_COM_LAST_WARNING,IQ4 Text for parameter GATEWAY_LAST_WARNING,IQ4 Text for parameter GATEWAY_EX_LAST_WARNING,IQ4 Text for parameter PERFORMAX_LAST_WARNING,IQ4 Text for parameter EASION_LAST_WARNING,IQ4 Text for parameter THUNDERION2_LAST_WARNING,IQ4 Text for parameter VICINION_LAST_WARNING,IQ4 Text for parameter CLEANFLEX_LAST_WARNING,IQ4 Text for parameter BLOWFLEX_LAST_WARNING,
    'Last warning date-time': 'Datum/tijd laatste waarschuwing',

    // ../src/templates/user-edit.html,src/templates/user-new.html,
    'Level': 'Niveau',

    // ../src/templates/debug-test.html,src/templates/debug.html,
    'Log': 'Logboek',

    // ../src/templates/debug-test.html,
    'Loop selection': 'Herhaal selectie',

    // IQ4 Text for parameter CM_TINY_MACHINE_POSITION,IQ4 Text for parameter CMME_MACHINE_POSITION,IQ4 Text for parameter IQ_COM_MACHINE_POSITION,IQ4 Text for parameter GATEWAY_MACHINE_POSITION,IQ4 Text for parameter GATEWAY_EX_MACHINE_POSITION,IQ4 Text for parameter PERFORMAX_MACHINE_POSITION,IQ4 Text for parameter EASION_MACHINE_POSITION,IQ4 Text for parameter PERFORMAX_EX_MACHINE_POSITION,IQ4 Text for parameter THUNDERION2_MACHINE_POSITION,IQ4 Text for parameter VICINION_MACHINE_POSITION,IQ4 Text for parameter CLEANFLEX_MACHINE_POSITION,IQ4 Text for parameter BLOWFLEX_MACHINE_POSITION,
    'Machine position (location)': 'Machine positie (locatie)',

    // IQ4 Text for parameter CM_TINY_CHARGE_PULSE_MAX_TIME,IQ4 Text for parameter CMME_CHARGE_PULSE_MAX_TIME,IQ4 Text for parameter IQ_COM_CHARGE_PULSE_MAX_TIME,
    'Max. Charge pulse duration': 'Max. duur laadpuls',

    // IQ4 Text for parameter CM_TINY_MAX_INTERNAL_TEMP,IQ4 Text for parameter CMME_MAX_INTERNAL_TEMP,IQ4 Text for parameter PERFORMAX_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter EASION_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter PERFORMAX_EX_INTERNAL_TEMPERATURE_MAX,IQ4 Text for parameter VICINION_MAX_INTERNAL_TEMPERATURE,
    'Max. internal temperature': 'Max. interne temperatuur',

    // IQ4 Text for parameter PERFORMAX_PRIMARY_CURRENT_MAX_OLD,IQ4 Text for parameter EASION_PRIMARY_CURRENT_MAX_OLD,IQ4 Text for parameter PERFORMAX_EX_PRIMARY_CURRENT_MAX_OLD,
    'Max. primary current': 'Max. primaire stroom',

    // IQ4 Text for parameter CM_TINY_MAX_QUANTITY_ARCS,IQ4 Text for parameter IQ_COM_MAX_QUANTITY_ARCS,
    'Max. quantity arcs per minute': 'Max. hoeveelheid overslagen per minuut',

    // ../src/templates/parameter.html,src/templates/chart.html,
    'Maximum value': 'Maximum waarde',

    // ../src/templates/log.html,
    'Message': 'Bericht',

    // IQ4 Text for parameter CM_TINY_CHARGE_PULSE_MIN_TIME,IQ4 Text for parameter CMME_CHARGE_PULSE_MIN_TIME,IQ4 Text for parameter IQ_COM_CHARGE_PULSE_MIN_TIME,
    'Min. Charge pulse duration': 'Min. duur ladingspuls',

    // ../src/templates/parameter.html,src/templates/chart.html,
    'Minimum value': 'Minimale waarde',

    // IQ4 Text for parameter GATEWAY_MOUNTING_DISTANCE,IQ4 Text for parameter GATEWAY_EX_MOUNTING_DISTANCE,IQ4 Text for parameter SEGMENT_4_QID_MOUNTING_DISTANCE,IQ4 Text for parameter PERFORMAX_MOUNTING_DISTANCE,IQ4 Text for parameter EASION_MOUNTING_DISTANCE,IQ4 Text for parameter PERFORMAX_EX_MOUNTING_DISTANCE,IQ4 Text for parameter THUNDERION2_MOUNTING_DISTANCE,IQ4 Text for parameter VICINION_MOUNTING_DISTANCE,
    'Mounting distance': 'Montageafstand',

    // ../src/templates/user-edit.html,src/templates/user-new.html,IQ4 Text for setting FIELDBUS_NAME,IQ4 Text for setting CLFB_NAME,
    'Name': 'Naam',

    // ../src/templates/related-add.html,
    'New Closed Loop Feedback': 'Nieuwe Closed Loop Feedback',

    // ../src/../src/core/gui.js,../src/../src/core/gui.js,src/templates/user-delete.html,
    'No': 'Nee',

    // ../src/../src/core/gui.js,
    'Off': 'Uit',

    // ../src/../src/core/gui.js,
    'On': 'Aan',

    // IQ4 Text for parameter CM_TINY_OPERATION_MODE,IQ4 Text for parameter CMME_OPERATION_MODE,IQ4 Text for parameter IQ_COM_OPERATION_MODE,IQ4 Text for parameter PERFORMAX_BAR_OPERATION_MODE,IQ4 Text for parameter EASION_BAR_OPERATION_MODE,IQ4 Text for parameter PERFORMAX_EX_BAR_OPERATION_MODE,IQ4 Text for parameter THUNDERION2_OPERATION_MODE,IQ4 Text for parameter VICINION_OPERATION_MODE,
    'Operation mode': 'Bedrijfsmodus',

    // IQ4 Text for parameter CMME_MIN_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_MAX_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MIN_ALARM_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MAX_ALARM_CURRENT_PEAK_MNGR4,
    'Output current alarm limit': 'Uitgangsstroom alarm limiet',

    // IQ4 Text for parameter CMME_MIN_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter CMME_MAX_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MIN_WARNING_CURRENT_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_MAX_WARNING_CURRENT_PEAK_MNGR4,
    'Output current peak alarm limit': 'Uitgangsstroom piek alarm limiet',

    // IQ4 Text for parameter PERFORMAX_VSENSE_NEG,IQ4 Text for parameter EASION_VSENSE_NEG,IQ4 Text for parameter PERFORMAX_EX_VSENSE_NEG,IQ4 Text for parameter THUNDERION2_VSENSE_MON_NEG_MNGR4,
    'Output voltage negative emitters': 'Uitgangsspanning negatieve emitters',

    // IQ4 Text for parameter CM_TINY_OUTPUT_VOLTAGE_PEAK_MNGR4,IQ4 Text for parameter IQ_COM_OUTPUT_VOLTAGE_PEAK_MNGR4,IQ4 Text for parameter CMME_OUTPUT_VOLTAGE_PEAK_MNGR4,
    'Output voltage peak per cycle': 'Uitgangsspanning piek per cyclus',

    // IQ4 Text for parameter PERFORMAX_VSENSE_POS,IQ4 Text for parameter EASION_VSENSE_POS,IQ4 Text for parameter PERFORMAX_EX_VSENSE_POS,IQ4 Text for parameter THUNDERION2_VSENSE_MON_POS_MNGR4,
    'Output voltage positive emitters': 'Uitgangsspanning positieve emitters',

    // ../src/templates/404.html,
    'Page could not be found': 'Pagina kon niet worden gevonden',

    // ../src/templates/macros.html,
    'Parameter locked': 'Parameter vergrendeld',

    // ../src/templates/user-edit.html,src/templates/login.html,src/templates/user-new.html,
    'Password': 'Wachtwoord',

    // ../src/templates/login.html,src/templates/password-reset.html,
    'Password reset': 'Wachtwoord reset',

    // ../src/../src/views/parameter.js,
    'Please input a valid number': 'Voer een geldig getal in',

    // ../src/templates/parameter.html,
    'Plot 48h chart': 'Plot 48 uurs grafiek',

    // ../src/templates/parameter.html,
    'Plot realtime chart': 'Plot realtime grafiek',

    // IQ4 Text for parameter CM_TINY_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter CMME_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter IQ_COM_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter PERFORMAX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter EASION_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter THUNDERION2_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter VICINION_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter CLEANFLEX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter BLOWFLEX_POWER_SUPPLY_VOLTAGE,IQ4 Text for parameter PERFORMAX_EX_POWER_SUPPLY_VOLTAGE,
    'Power supply voltage': 'Voedingsspanning',

    // IQ4 Text for parameter CM_TINY_POWER_SUPPLY_WARNING_LEVEL,IQ4 Text for parameter CMME_POWER_SUPPLY_WARNING_LEVEL,IQ4 Text for parameter IQ_COM_POWER_SUPPLY_VOLTAGE_WRN_LEVEL,IQ4 Text for parameter PERFORMAX_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter EASION_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter VICINION_POWER_SUPPLY_VOLTAGE_WARNING_LEVEL,IQ4 Text for parameter PERFORMAX_EX_POWER_SUPPLY_VOLTAGE_WARNING,
    'Power supply warning level': 'Voedingsspanning waarschuwingsniveau',

    // IQ4 Text for parameter PERFORMAX_PRIMARY_CURRENT_OLD,IQ4 Text for parameter EASION_PRIMARY_CURRENT_OLD,IQ4 Text for parameter PERFORMAX_EX_PRIMARY_CURRENT_OLD,IQ4 Text for parameter THUNDERION2_PRIMARY_CURRENT,IQ4 Text for parameter CLEANFLEX_PRIMARY_CURRENT,IQ4 Text for parameter BLOWFLEX_PRIMARY_CURRENT,
    'Primary current': 'Primaire stroom',

    // IQ4 Text for setting CLFB_PROPORTIONALGAIN,
    'Proportional Gain': 'Proportionele Gain',

    // ../src/templates/chart.html,
    'Realtime chart': 'Realtime grafiek',

    // Action title for reboot,
    'Reboot': 'Herstarten',

    // ../src/templates/password-reset.html,
    'Recovery key': 'Herstelsleutel',

    // IQ4 Text for parameter GATEWAY_4_QID_HUMIDITY_SENSOR_VALUE,
    'Relative Humidity': 'Relatieve vochtigheid',

    // IQ4 Text for parameter GATEWAY_4_QID_HUMIDITY_SENSOR_AVAILABLE,
    'Relative Humidity sensor available': 'Relatieve luchtvochtigheidssensor beschikbaar',

    // ../src/templates/debug.html,
    'Report': 'Rapportage',

    // ../src/templates/password-reset.html,
    'Reset password': 'Wachtwoord resetten',

    // ../src/templates/debug.html,
    'Restart': 'Herstarten',

    // ../src/../src/core/state.js,
    'Restarting the system, please wait': 'Herstarten van het systeem, even geduld',

    // ../src/../src/core/state.js,
    'Restarting, please wait': 'Herstarten, even geduld',

    // Action title for restore-factory,
    'Restore factory settings': 'Herstel de fabrieksinstellingen',

    // ../src/templates/debug-test.html,
    'Result': 'Resultaat',

    // ../src/templates/debug-test.html,
    'Run': 'Start',

    // ../src/templates/debug-test.html,
    'Run test selection': 'Testselectie uitvoeren',

    // ../src/templates/user-edit.html,src/templates/parameter.html,src/templates/time.html,src/templates/time.html,src/templates/user-new.html,src/templates/setting.html,
    'Save': 'Opslaan',

    // IQ4 Text for setting CLFB_SEGMENTSELECTION,
    'Segment Selection': 'Segment Selectie',

    // ../src/templates/debug-report.html,
    'Send': 'Verzend',

    // ../src/templates/debug-test.html,
    'Sequences': 'Vervolgstappen',

    // Action title for run,
    'Set device in RUN': 'Zet apparaat in RUN',

    // ../src/templates/time.html,
    'Set local settings': 'Lokale instellingen instellen',

    // NB: ENGLISH SOURCE IS GONE, CANDIDATE FOR REMOVAL
    'Set new system time': 'Stel nieuwe systeemtijd in',

    // ../src/templates/settings-updates.html,src/templates/settings.html,
    'Settings': 'Instellingen',

    // ../src/templates/log.html,
    'Show all ': 'Alle tonen',

    // ../src/templates/settings-updates.html,
    'Show current versions': 'Toon huidige versies',

    // ../src/templates/related-delete.html,src/templates/related-delete.html,src/templates/related-add.html,src/templates/related-select-slot.html,Action title for show-related,
    'Show related objects': 'Toon gerelateerde objecten',

    // ../src/templates/settings-updates.html,
    'Show release notes': 'Toon release notities',

    // ../src/../src/views/chart.js,src/templates/chart.html,
    'Show table': 'Tabel weergeven',

    // ../src/templates/related-select-slot.html,
    'Slot ': 'Slot',

    // Action title for standby,
    'Standby': 'Stand-by',

    // ../src/../src/core/state.js,
    'Starting the system, please wait': 'Systeem starten, even geduld a.u.b',

    // ../src/templates/debug-state.html,src/templates/debug.html,
    'State': 'Status',

    // ../src/templates/debug-test.html,
    'Steps': 'Stappen',

    // ../src/templates/debug.html,
    'Tests': 'Testen',

    // ../src/../src/views/time.js,
    'The local time settings were changed successfully': 'De lokale tijdinstellingen zijn met succes gewijzigd',

    // ../src/templates/password-reset.html,
    'The new password is': 'Het nieuwe wachtwoord is',

    // ../src/templates/password-reset.html,
    'The password for ': 'Het wachtwoord voor',

    // ../src/../src/views/time.js,
    'The system time was changed successfully': 'De systeemtijd is succesvol gewijzigd',

    // ../src/templates/related-add.html,
    'There are no objects to add.': 'Er zijn geen objecten om toe te voegen.',

    // ../src/templates/related-delete.html,
    'There are no objects to delete.': 'Er zijn geen objecten om te verwijderen.',

    // ../src/templates/related.html,
    'There are no related objects.': 'Er zijn geen gerelateerde objecten.',

    // ../src/templates/settings-updates.html,
    'There are no updates available': 'Er zijn geen updates beschikbaar.',

    // ../src/templates/settings-install-updates.html,
    'This might take a while and the system will reboot.': 'Dit kan even duren en het systeem zal opnieuw opstarten.',

    // ../src/templates/settings-confirm-update.html,
    'This will reboot the entire system and cause some downtime.': 'Dit zal het hele systeem opnieuw opstarten en enige vertraging veroorzaken.',

    // IQ4 Text for parameter CM_TINY_TIME_RUNNING,IQ4 Text for parameter CMME_TIME_RUNNING,IQ4 Text for parameter IQ_COM_TIME_RUNNING,IQ4 Text for parameter GATEWAY_TIME_RUNNING,IQ4 Text for parameter GATEWAY_EX_TIME_RUNNING,IQ4 Text for parameter GATEWAY_4_QID_DEVICE_UPTIME,IQ4 Text for parameter SEGMENT_4_QID_DEVICE_UPTIME,IQ4 Text for parameter PERFORMAX_TIME_RUNNING,IQ4 Text for parameter EASION_TIME_RUNNING,IQ4 Text for parameter PERFORMAX_EX_TIME_RUNNING,IQ4 Text for parameter THUNDERION2_TIME_RUNNING,IQ4 Text for parameter VICINION_TIME_RUNNING,IQ4 Text for parameter CLEANFLEX_TIME_RUNNING,IQ4 Text for parameter BLOWFLEX_TIME_RUNNING,
    'Time running since power up': 'Tijd actief sinds power up',

    // IQ4 Text for parameter CMME_TIME_TO_CYCLE_OK,
    'Time to Cycle OK': 'Tijd tot cycle OK',

    // ../src/templates/time.html,
    'Timezone': 'Tijdzone',

    // IQ4 Text for parameter GATEWAY_4_QID_TOTAL_RUNNING_TIME,IQ4 Text for parameter SEGMENT_4_QID_TOTAL_RUNNING_TIME,
    'Total running time': 'Totale tijd actief',

    // IQ4 Text for parameter CM_TINY_UNIQUE_DEVICE_ID,IQ4 Text for parameter CMME_UNIQUE_DEVICE_ID,IQ4 Text for parameter IQ_COM_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_EX_UNIQUE_DEVICE_ID,IQ4 Text for parameter GATEWAY_4_QID_UNIQUE_DEVICE_ID,IQ4 Text for parameter SEGMENT_4_QID_UNIQUE_DEVICE_ID,IQ4 Text for parameter PERFORMAX_UNIQUE_DEVICE_ID,IQ4 Text for parameter EASION_UNIQUE_DEVICE_ID,IQ4 Text for parameter PERFORMAX_EX_UNIQUE_DEVICE_ID,IQ4 Text for parameter THUNDERION2_UNIQUE_DEVICE_ID,IQ4 Text for parameter VICINION_UNIQUE_DEVICE_ID,IQ4 Text for parameter CLEANFLEX_UNIQUE_DEVICE_ID,IQ4 Text for parameter BLOWFLEX_UNIQUE_DEVICE_ID,
    'Unique device ID': 'Unieke apparaat-ID',

    // ../src/templates/error.html,
    'Unknown error': 'Onbekende fout',

    // ../src/templates/macros.html,
    'Unknown object': 'Onbekend object',

    // ../src/templates/settings-confirm-update.html,Action title for update,
    'Update': 'Werk bij',

    // ../src/../src/views/settings.js,src/templates/settings.html,
    'Updating': 'Bijwerken',

    // ../src/../src/core/state.js,
    'Updating the system, please wait': 'Systeem wordt bijgewerkt, even geduld a.u.b',

    // ../src/templates/related.html,src/templates/related.html,
    'Use the + button to add a related object.': 'Gebruik de + knop om een verwant object toe te voegen.',

    // ../src/templates/user.html,
    'User': 'Gebruiker',

    // ../src/../src/views/user.js,
    'User created successfully': 'Gebruiker succesvol aangemaakt',

    // ../src/../src/views/user.js,
    'User deleted successfully': 'Gebruiker succesvol verwijderd',

    // ../src/../src/views/user.js,
    'User updated successfully': 'Gebruiker succesvol bijgewerkt',

    // ../src/templates/login.html,src/templates/password-reset.html,
    'Username': 'Gebruikersnaam',

    // ../src/templates/settings.html,
    'Versions': 'Versies',

    // IQ4 Text for parameter CM_TINY_SETPOINT_VOLTAGE_MNGR4,IQ4 Text for parameter IQ_COM_SETPOINT_VOLTAGE_60KV_MNGR4,IQ4 Text for parameter VICINION_SETPOINT_VOLTAGE,IQ4 Text for parameter CMME_SETPOINT_VOLTAGE_MNGR4,
    'Voltage Control voltage setpoint': 'Spanningsregeling instelpunt',

    // ../src/../src/main.js,../src/../src/core/websocket.js,
    'Waiting for server connection, please wait': 'Wachten op verbinding met de server. Een ogenblik geduld',

    // IQ4 Text for parameter GATEWAY_WARNING_SETPOINT,IQ4 Text for parameter GATEWAY_EX_WARNING_SETPOINT,IQ4 Text for parameter SEGMENT_4_QID_WARNING_SETPOINT,
    'Warning level, measured value over maximum': 'Waarschuwing niveau, gemeten waarde over het maximum',

    // IQ4 Text for parameter GATEWAY_WARNING_MINIMUM,IQ4 Text for parameter GATEWAY_EX_WARNING_MINIMUM,IQ4 Text for parameter SEGMENT_4_QID_WARNING_MINIMUM,
    'Warning level, measured value under minimum': 'Waarschuwing niveau, gemeten waarde onder minimum',

    // IQ4 Text for parameter GATEWAY_WARNING_ERROR_DELAY,IQ4 Text for parameter GATEWAY_EX_WARNING_ERROR_DELAY,
    'Warning/alarm delay': 'Waarschuwing/alarm vertraging',

    // ../src/templates/log.html,
    'Warnings': 'Waarschuwingen',

    // IQ4 Text for parameter PERFORMAX_BAR_WEB_SPEED,IQ4 Text for parameter EASION_WEB_SPEED,IQ4 Text for parameter PERFORMAX_EX_BAR_WEB_SPEED,
    'Web speed': 'Baansnelheid',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_AVERAGE_ALL_SEG,IQ4 Text for parameter GATEWAY_EX_IQ4_HIRES_WEB_VOLTAGE_AVERAGE_ALL_SEG,
    'Web voltage average (all)': 'Gemiddelde baanspanning (alles)',

    // IQ4 Text for setting CLFB_WEBVOLTAGEOFFSET,
    'Web voltage offset': 'Baanspanning offset',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG1,IQ4 Text for parameter GATEWAY_EX_IQ4_HIRES_WEB_VOLTAGE_SEG1,
    'Web voltage seg1': 'Baanspanning seg1',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG10,
    'Web voltage seg10': 'Baanspanning seg10',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG11,
    'Web voltage seg11': 'Baanspanning seg11',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG12,
    'Web voltage seg12': 'Baanspanning seg12',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG13,
    'Web voltage seg13': 'Baanspanning seg13',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG14,
    'Web voltage seg14': 'Baanspanning seg14',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG15,
    'Web voltage seg15': 'Baanspanning seg15',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG16,
    'Web voltage seg16': 'Baanspanning seg16',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG2,IQ4 Text for parameter GATEWAY_EX_IQ4_HIRES_WEB_VOLTAGE_SEG2,
    'Web voltage seg2': 'Baanspanning seg2',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG3,IQ4 Text for parameter GATEWAY_EX_IQ4_HIRES_WEB_VOLTAGE_SEG3,
    'Web voltage seg3': 'Baanspanning seg3',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG4,IQ4 Text for parameter GATEWAY_EX_IQ4_HIRES_WEB_VOLTAGE_SEG4,
    'Web voltage seg4': 'Baanspanning seg4',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG5,
    'Web voltage seg5': 'Baanspanning seg5',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG6,
    'Web voltage seg6': 'Baanspanning seg6',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG7,
    'Web voltage seg7': 'Baanspanning seg7',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG8,
    'Web voltage seg8': 'Baanspanning seg8',

    // IQ4 Text for parameter GATEWAY_IQ4_HIRES_WEB_VOLTAGE_SEG9,
    'Web voltage seg9': 'Baanspanning seg9',

    // IQ4 Text for parameter PERFORMAX_BAR_WEB_WIDTH,IQ4 Text for parameter EASION_WEB_WIDTH,IQ4 Text for parameter PERFORMAX_EX_BAR_WEB_WIDTH,
    'Web width': 'Baanbreedte',

    // ../src/../src/core/gui.js,../src/../src/core/gui.js,src/templates/user-delete.html,
    'Yes': 'Ja',

    // ../src/templates/user.html,
    'You are currently logged in as the user': 'U bent momenteel aangemeld als gebruiker',

    // ../src/../src/views/user.js,
    'You can not delete your own user account': 'U kunt uw eigen gebruikersaccount niet verwijderen',

    // ../src/templates/password-reset.html,
    'has been reset': 'is opnieuw ingesteld',

    // ../src/templates/user.html,
    'userlevel': 'gebruikersniveau',

    // ../src/templates/user.html,
    'username': 'gebruikersnaam',

    // ====================================================
    // TODO: translate these
    // ====================================================
    // ../src/templates/user-edit.html, src/templates/settings-updates.html, 
    'Id': 'Id', 

    // ../src/templates/debug-test.html, 
    'Test': 'Test', 

    // ../src/templates/user.html, 
    'User list': 'User list', 

    // ../src/templates/user.html, 
    'id': 'id', 

    // ../src/templates/login.html, 
    'Language': 'Language', 

    // ../src/templates/time.html, 
    'Current system time in UTC': 'Current system time in UTC', 

    // ../src/templates/time.html, 
    'Current time zone': 'Current time zone', 

    // ../src/templates/time.html, 
    'Set new system time in UTC': 'Set new system time in UTC', 

    // ../src/templates/time.html, 
    'Time': 'Time', 

    // ../src/templates/time.html, 
    '24H': '24H', 

    // ../src/templates/time.html, 
    'AM/PM': 'AM/PM', 

    // ../src/templates/chart.html, 
    'Reset zoom': 'Reset zoom', 

    // ../src/templates/related-select-slot.html, 
    'Select fieldbus slot': 'Select fieldbus slot', 

    // ../src/templates/log.html, 
    'Log for': 'Log for', 

    // ../src/templates/log.html, 
    'Filter': 'Filter', 

    // ../src/templates/log.html, 
    'Dismiss log': 'Dismiss log', 

    // ../src/templates/log.html, 
    'Show dismissed': 'Show dismissed', 

    // ../src/templates/log.html, src/templates/settings-updates.html, src/templates/settings.html, 
    'Type': 'Type', 

    // ../src/templates/log.html, 
    'Code': 'Code', 

    // ../src/templates/debug.html, 
    'Debug': 'Debug', 

    // ../src/templates/settings-updates.html, src/templates/settings.html, 
    'UID': 'UID', 

    // ../src/templates/settings-updates.html, 
    'Release notes': 'Release notes', 

    // IQ4 Text for event WARNING_MASK_NONE, IQ4 Text for event ALARM_MASK_NONE, 
    'none': 'none', 

    // IQ4 Text for event SYS_WARNING_MASK_NEW_DEVICE_CONNECTED, 
    'new device connected': 'new device connected', 

    // IQ4 Text for event SYS_WARNING_MASK_DEVICE_PORT_CHANGED, 
    'device port changed': 'device port changed', 

    // IQ4 Text for event WARNING_MASK_POWER_SUPPLY_LOW, 
    'power supply low': 'power supply low', 

    // IQ4 Text for event WARNING_MASK_LEVEL_TOO_HIGH, IQ4 Text for event ALARM_MASK_LEVEL_TOO_HIGH, 
    'level too high': 'level too high', 

    // IQ4 Text for event WARNING_MASK_OVERLOAD_HIGH_VOLTAGE, IQ4 Text for event ALARM_MASK_OVERLOAD_HIGH_VOLTAGE, 
    'overload high voltage': 'overload high voltage', 

    // IQ4 Text for event WARNING_MASK_CHARGEPULSE_TOO_LONG, 
    'chargepulse too long': 'chargepulse too long', 

    // IQ4 Text for event WARNING_MASK_SENSOR_MISSING, 
    'sensor missing': 'sensor missing', 

    // IQ4 Text for event WARNING_MASK_PAIRED_DEVICE_MISSING, 
    'paired device missing': 'paired device missing', 

    // IQ4 Text for event WARNING_MASK_NO_COMM_PAIRED_DEVICE, 
    'no comm paired device': 'no comm paired device', 

    // IQ4 Text for event WARNING_MASK_NO_ACTIVE_SEGMENTS, 
    'no active segments': 'no active segments', 

    // IQ4 Text for event WARNING_MASK_ARC_DETECTED, IQ4 Text for event ALARM_MASK_ARC_DETECTED, 
    'arc detected': 'arc detected', 

    // IQ4 Text for event WARNING_MASK_LEVEL_TOO_LOW, IQ4 Text for event ALARM_MASK_LEVEL_TOO_LOW, 
    'level too low': 'level too low', 

    // IQ4 Text for event WARNING_MASK_EEPROM_TOO_MANY_WRITES, 
    'eeprom too many writes': 'eeprom too many writes', 

    // IQ4 Text for event WARNING_MASK_EEPROM_PREV_SETTINGS_REST, 
    'eeprom prev settings rest': 'eeprom prev settings rest', 

    // IQ4 Text for event WARNING_MASK_24V_OUTPUT_OVERLOAD, 
    '24v output overload': '24v output overload', 

    // IQ4 Text for event WARNING_MASK_GENERATOR_NOT_CONNECTED, 
    'generator not connected': 'generator not connected', 

    // IQ4 Text for event WARNING_MASK_HMI_COMM_LOST, 
    'hmi comm lost': 'hmi comm lost', 

    // IQ4 Text for event ALARM_MASK_EEPROM_DEFECTIVE, 
    'eeprom defective': 'eeprom defective', 

    // IQ4 Text for event ALARM_MASK_HIGH_TEMP, IQ4 Text for event WARNING_MASK_HIGH_TEMP, 
    'high temperature': 'high temperature', 

    // IQ4 Text for event ERR_ID_NEW_DEVICE, 
    'err id new device': 'err id new device', 

    // IQ4 Text for event ERR_ID_PORT_SWAP, 
    'err id port swap': 'err id port swap', 

    // IQ4 Text for event ERR_ID_WEB_VOLTAGE_HIGH_WARNING, 
    'err id web voltage high': 'err id web voltage high', 

    // IQ4 Text for event ERR_ID_CALIBRATION_INVALID, 
    'err id calibration invalid': 'err id calibration invalid', 

    // IQ4 Text for event ERR_ID_WEB_VOLTAGE_LOW_WARNING, 
    'err id web voltage low': 'err id web voltage low', 

    // IQ4 Text for event ERR_ID_COMMUNICATION_LOST, 
    'err id communication lost': 'err id communication lost', 

    // IQ4 Text for event ERR_ID_WEB_VOLTAGE_HIGH_ERROR, 
    'err id web voltage high error': 'err id web voltage high error', 

    // IQ4 Text for event ERR_ID_SENSOR_MALFUNCTION, 
    'err id sensor malfunction': 'err id sensor malfunction', 

    // IQ4 Text for event ERR_ID_WEB_VOLTAGE_OVERLOAD, 
    'err id web voltage overload': 'err id web voltage overload', 

    // IQ4 Text for event ERR_ID_WEB_VOLTAGE_LOW_ERROR, 
    'err id web voltage low error': 'err id web voltage low error', 

    // IQ4 Text for event ERR_ID_COMMUNICATION_LOST_SEGMENT, 
    'err id communication lost segment': 'err id communication lost segment', 

    // IQ4 Text for event ERR_ID_SUPPLY_VOLTAGE_LOW, 
    'err id supply voltage low': 'err id supply voltage low', 

    // IQ4 Text for event ERR_ID_EFFICIENCY_LOW, 
    'err id efficiency low': 'err id efficiency low', 

    // IQ4 Text for event ERR_ID_CALIBRATION_IN_PROGRESS, 
    'err id calibration in progress': 'err id calibration in progress', 

    // IQ4 Text for event ERR_ID_HVOUT_OVERLOAD_WARNING, 
    'err id hvout overload': 'err id hvout overload', 

    // IQ4 Text for event ERR_ID_TEMPERATURE_WARNING, 
    'err id temperature': 'err id temperature', 

    // IQ4 Text for event ERR_ID_LINKED_SENSOR_SEGMENT_MISSING, 
    'err id linked sensor segment missing': 'err id linked sensor segment missing', 

    // IQ4 Text for event ERR_ID_LINKED_SENSOR_PAIRED_DEVICE_MISSING, 
    'err id linked sensor paired device missing': 'err id linked sensor paired device missing', 

    // IQ4 Text for event ERR_ID_LINKED_SENSOR_NO_COMM_PAIRED_DEVICE, 
    'err id linked sensor no comm paired device': 'err id linked sensor no comm paired device', 

    // IQ4 Text for event ERR_ID_LINKED_SENSOR_NO_ACTIVE_SEGMENTS, 
    'err id linked sensor no active segments': 'err id linked sensor no active segments', 

    // IQ4 Text for event ERR_ID_TEMPERATURE_ERROR, 
    'err id temperature error': 'err id temperature error', 

    // IQ4 Text for event ERR_ID_SUPPLY_CURRENT_HIGH, 
    'err id supply current high': 'err id supply current high', 

    // IQ4 Text for event ERR_ID_HVOUT_OVERLOAD_ERROR, 
    'err id hvout overload error': 'err id hvout overload error', 

    // IQ4 Text for event WARNING_MASK_CLEAN_BAR, 
    'clean bar': 'clean bar', 

    // IQ4 Text for event ERROR_MASK_HIGH_PRIM_CUR, 
    'error high prim cur': 'error high prim cur', 

};

export function getTranslations() {
    return translations;
}