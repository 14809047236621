/**
 * MedCombineResult view module.
 */

"use strict";

import * as Gui from '../core/gui';
import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as State from '../core/state';
import * as Signals from '../core/signals';
import * as Debug from '../core/debug';
import * as Helpers from '../core/helpers';
import * as Session from '../core/session';

/**
 * MedCombineResult view class
 */
export class MedCombineResultView {
    constructor() {
        this.viewName = 'MedCombineResultView';
        this.listenerIds = [];
        this.loadingTimeout = -1;
        this.actionHandlerIds = [];
        this.listenerIds = [];
        this.data = State.getData();
        this.name = '';
    }

    destructor() {
        Signals.removeListeners(this.listenerIds);
        Actions.removeActionHandlers(this.actionHandlerIds);
    }

    addFavorite() {
        Session.addFavorite(this.name);
        this.showPage();
    }

    removeFavorite() {
        Session.removeFavorite();
        this.showPage();
    }

    showPage() {
        let context = {
            existing: this.existing,
            add: this.add,
            medExisting: this.data[this.existing],
            medAdd: this.data[this.add],
            isFavorite: Session.isFavorite(),
        };

        Gui.setCombineTitle(
            this.data[this.existing].name,
            this.data[this.add].name
        );

        this.name = this.data[this.existing].name + ' + ' + this.data[this.add].name;

        Gui.showBackButton();
        Gui.render('medcombineresult.html', context);
    }

    render(event, args) {
        this.existing = args.existing;
        this.add = args.add;

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                'add-favorite', 'add-favorite', this.addFavorite.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                'remove-favorite', 'remove-favorite', this.removeFavorite.bind(this)
            )
        );

        this.showPage();
    }

}
