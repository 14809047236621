/**
 * State module.
 * The State module keeps track of all the devices and their states.
 * This module only keeps a shallow copy of the device data. For retrieving the
 * full device description including a list of all the parameters you need to
 * call the API.
 * Whenever there's an update from the manager the tracked state is updated and
 * uses as a reference for updating the screens.
 * @module state
 */

"use strict";

import * as Api from './api';
import * as Helpers from './helpers';
import * as Debug from './debug';
import * as Signals from './signals';
import * as Session from './session';

let state = {};
let data = {};

/**
 * Initialize the state module. Retrieve a list of devices from the API.
 * @returns {Promise}
 */
export function initState() {
    Debug.log('Initializing state module');
    return new Promise((resolve, reject) => {
        Api.getData().then((d) => {
            data = d;
            console.log(d);
            return resolve();
        }).catch((err) => {
            // TODO: proper error message
            Debug.log(err);
            return reject(err);
        });
    });
}

/**
 * @returns {object} data
 */
export function getData() {
    return data;
}
