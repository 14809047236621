"use strict";

import * as Debug from './debug';
import * as Session from './session';

/**
 * The Url class holds a url pattern, a viewclass and a string reference to
 * the view function that handles visiting this url.
 */
export class Url {
    /**
     * Constructs a new Url instance
     * @param {string} url - the url pattern, this can contain parameters surrounded by
     *            curly braces
     * @param {Object} ViewClass - the View class this Url is tied to
     * @param {string} viewFunction - the string reference to the view function that
     *                     handles visiting this url
     * @param {boolean} loginRequired - whether you need to be logged in to
     *                                  visit this url
     */
    constructor(url, ViewClass, viewFunction = 'render', loginRequired = false) {
        this.url = url;
        this.ViewClass = ViewClass;
        this.viewFunction = viewFunction;
        this.route = routeMatcher(url);
        this.currentView = null;
        this.loginRequired = loginRequired;
    }

    /**
     * Call the current view's destructor (if there is one).
     * This is called by the router whenever a new url is visited.
     */
    destructor() {
        if(this.currentView && this.currentView.destructor){
            this.currentView.destructor();
        }
    }

    /**
     * Match a given url to the url pattern
     * @param {string} inputUrl - the input url
     * @returns {boolean} whether the given url matches this Url instance
     */
    match(inputUrl) {
        return this.route.parse(inputUrl) !== null;
    }

    /**
     * Visit the given url. Creates a new instance from this Url's ViewClass,
     * and tries to call the viewFunction
     * @param {string} url - the url as a string, we assume that this matches with this
     *            Url instance
     * @param {Event} event - the event that triggered this visit
     * @throws ViewFunctionNotFound if the View class does not have the
     *         function represented by this url's viewFunction
     */
    visit(url, event, data){
        Debug.log("Matched url, going to visit" + url);

        let view = new this.ViewClass();

        if(!(view)[this.viewFunction]){
            throw {
                'name': 'ViewFunctionNotFound',
                'message': `Could not find view function ${this.viewFunction} in class ` +
                    `${this.ViewClass.viewName}, while trying to visit url "${url}"`
            };
        }

        (view)[this.viewFunction](event, this._parseArgs(url), data);
        this.currentView = view;
    }

    /**
     * Parses url arguments inside given url, and returns them
     * @param {string} url - the url string
     * @returns UrlArguments the arguments present in this url (if any)
    */
    _parseArgs(url) {
        return this.route.parse(url);
    }
}
