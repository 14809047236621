"use strict";

export const VERSION = '2.0.0';

export const MAXIMA = {
    Diazepam: 200,
    Oxazepam: 200,
    Chlordiazepoxide: 120,
}

export const BENZO_TABLE = {
    'Alprazolam-Diazepam': 10,
    'Alprazolam-Oxazepam': 33.33,
    'Alprazolam-Chlordiazepoxide': 20,
    'Bromazepam-Diazepam': 1,
    'Bromazepam-Oxazepam': 3.33,
    'Bromazepam-Chlordiazepoxide': 2,
    'Brotizolam-Diazepam': 40,
    'Brotizolam-Oxazepam': 133.33,
    'Brotizolam-Chlordiazepoxide': 80,
    'Chlordiazepoxide-Diazepam': 0.5,
    'Chlordiazepoxide-Oxazepam': 1.67,
    'Chlordiazepoxide-Chlordiazepoxide': 1,
    'Clobazam-Diazepam': 0.5,
    'Clobazam-Oxazepam': 1.67,
    'Clobazam-Chlordiazepoxide': 1,
    'Clonazepam-Diazepam': 20,
    'Clonazepam-Oxazepam': 66.67,
    'Clonazepam-Chlordiazepoxide': 40,
    'Clorazepate-Diazepam': 0.75,
    'Clorazepate-Oxazepam': 2.5,
    'Clorazepate-Chlordiazepoxide': 1.5,
    'Diazepam-Diazepam': 1,
    'Diazepam-Oxazepam': 3.33,
    'Diazepam-Chlordiazepoxide': 2,
    'Flunitrazepam-Diazepam': 10,
    'Flunitrazepam-Oxazepam': 33.33,
    'Flunitrazepam-Chlordiazepoxide': 20,
    'Flurazepam-Diazepam': 0.33,
    'Flurazepam-Oxazepam': 1.1,
    'Flurazepam-Chlordiazepoxide': 0.66,
    'Ketazolam-Diazepam': 0.17,
    'Ketazolam-Oxazepam': 0.57,
    'Ketazolam-Chlordiazepoxide': 0.34,
    'Loprazolam-Diazepam': 10,
    'Loprazolam-Oxazepam': 33.33,
    'Loprazolam-Chlordiazepoxide': 20,
    'Lorazepam-Diazepam': 5,
    'Lorazepam-Oxazepam': 16.67,
    'Lorazepam-Chlordiazepoxide': 10,
    'Lormetazepam-Diazepam': 10,
    'Lormetazepam-Oxazepam': 33.33,
    'Lormetazepam-Chlordiazepoxide': 20,
    'Medazepam-Diazepam': 0.5,
    'Medazepam-Oxazepam': 1.67,
    'Medazepam-Chlordiazepoxide': 1,
    'Nitrazepam-Diazepam': 1,
    'Nitrazepam-Oxazepam': 3.33,
    'Nitrazepam-Chlordiazepoxide': 2,
    'Oxazepam-Diazepam': 0.3,
    'Oxazepam-Oxazepam': 1,
    'Oxazepam-Chlordiazepoxide': 0.6,
    'Prazepam-Diazepam': 0.5,
    'Prazepam-Oxazepam': 1.67,
    'Prazepam-Chlordiazepoxide': 1,
    'Temazepam-Diazepam': 0.5,
    'Temazepam-Oxazepam': 1.67,
    'Temazepam-Chlordiazepoxide': 1,
    'Zolpidem-Diazepam': 1,
    'Zolpidem-Oxazepam': 3.33,
    'Zolpidem-Chlordiazepoxide': 2,
    'Zopiclone-Diazepam': 1.33,
    'Zopiclone-Oxazepam': 4.43,
    'Zopiclone-Chlordiazepoxide': 2.66,
}
