/**
 * Session module.
 * The Session module handles everything that is session specific, like
 * language setting, screen preferences..etc
 * @module session
 */

"use strict";

import {log} from './debug';

export let sessionVariables = {};

/**
 * Initialize the current session, retrieving configuration from the
 * localStorage and keeping that available as session variables.
 * @param {function} callback - called when initialization is complete
 */
export function initSession(callback=()=>{}) {
    let sesVars = window.localStorage.getItem('sessionVariables');
    if(sesVars !== null){
        sessionVariables = JSON.parse(sesVars);
    }

    if(!sessionVariables.hasOwnProperty('favorites')) {
        sessionVariables.favorites = {};
    }

    setVar('uuid', generateUuid());
    callback();
}

/**
 * Generate a new UUID
 * @returns {string}
 */
function generateUuid() {
    /* jslint bitwise: true */
    let d = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c==='x' ? r : (r&0x3|0x8)).toString(16);
    });
    /* jslint bitwise: false */
    return uuid;
}

/**
 * Retrieve the uuid
 * @returns {string}
 */
export function getUuid() {
    return getVar('uuid');
}

/**
 * Set the currently selected language to the given one
 * @param {string} language - ISO 2 letter language code
 */
export function setLanguage(language) {
    return window.localStorage.setItem('language', language);
}

/**
 * Return the currently selected language, which defaults to 'en'
 * @return {string} - the language as an ISO 2 letter code
 */
export function getLanguage() {
    return window.localStorage.getItem('language') || 'en';
}

/**
 * Set a session variable to the given value and also update the localStorage
 * @param {string} name
 * @param {string|boolean} value
 */
export function setVar(name, value) {
    sessionVariables[name] = value;
    storeSessionVariables();
}

/**
 * Remove session variable with given name, and also remove it from the localStorage
 */
export function deleteVar(name) {
    delete sessionVariables[name];
    storeSessionVariables();
}

function storeSessionVariables() {
    window.localStorage.setItem('sessionVariables', JSON.stringify(sessionVariables));
}

/**
 * Retrieve a given session variable, or the default when it can not be found
 * @param {string} name
 * @param {string|false} [defaultValue=false] - value to return when not present (defaults to false)
 * @return {any}
 */
export function getVar(name, defaultValue=false) {
    return sessionVariables[name] || defaultValue;
}

/**
 * Completely remove all session variables and also wipe them from the local
 * storage
 */
export function deleteAllVars() {
    sessionVariables = {};
    storeSessionVariables();
}

export function addFavorite(name) {
    const url = window.location.hash;
    sessionVariables.favorites[url] = name;
    storeSessionVariables();
}

export function isFavorite() {
    const url = window.location.hash;
    return sessionVariables.favorites.hasOwnProperty(url);
}

export function removeFavorite(url=false) {
    if(!url) {
        url = window.location.hash;
    }
    if(sessionVariables.favorites.hasOwnProperty(url)) {
        delete sessionVariables.favorites[url];
    }
    storeSessionVariables();
}

export function getFavorites() {
    return sessionVariables.favorites;
}
