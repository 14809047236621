/**
 * Favorites view module.
 */

"use strict";

import * as Gui from '../core/gui';
import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as State from '../core/state';
import * as Signals from '../core/signals';
import * as Debug from '../core/debug';
import * as Helpers from '../core/helpers';
import * as Session from '../core/session';

/**
 * Favorites view class
 */
export class FavoritesView {
    constructor() {
        this.viewName = 'FavoritesView';
        this.listenerIds = [];
        this.loadingTimeout = -1;
        this.actionHandlerIds = [];
        this.listenerIds = [];
        this.showRemove = false;
    }

    destructor() {
        Signals.removeListeners(this.listenerIds);
        Actions.removeActionHandlers(this.actionHandlerIds);
    }

    toggleRemove(actionId, args, element) {
        this.showRemove = !this.showRemove;
        if(this.showRemove) {
            $('a.delete').fadeIn();
            $('a[data-id=toggle-remove] i').html('close');
        } else {
            $('a.delete').fadeOut();
            $('a[data-id=toggle-remove] i').html('edit');
        }
    }

    removeFavorite(actionId, args, element) {
        Session.removeFavorite(args.param);
        $(element).parents('li').remove();
    }

    render(event, args) {
        Gui.showBackButton();
        Gui.setTitle('Favorites');
        Debug.log(Helpers.sortObjectByValue(Session.getFavorites()));

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                'toggle-remove', 'toggle-remove', this.toggleRemove.bind(this)
            )
        );

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                'remove-favorite', 'remove-favorite', this.removeFavorite.bind(this)
            )
        );

        let context = {
            favorites: Helpers.sortObjectByValue(Session.getFavorites()),
        };
        Gui.render('favorites.html', context);
    }

}
