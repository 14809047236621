/**
 * BenzoConversion view module.
 */

"use strict";

import * as Gui from '../core/gui';
import * as Constants from '../core/constants';
import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as State from '../core/state';
import * as Signals from '../core/signals';
import * as Debug from '../core/debug';
import * as Helpers from '../core/helpers';

/**
 * BenzoConversion view class
 */
export class BenzoConversionView {
    constructor() {
        this.viewName = 'BenzoConversionView';
        this.listenerIds = [];
        this.loadingTimeout = -1;
        this.actionHandlerIds = [];
        this.listenerIds = [];
        this.selectFields = [];
        this.data = State.getData();
    }

    destructor() {
        Signals.removeListeners(this.listenerIds);
        Actions.removeActionHandlers(this.actionHandlerIds);
    }

    submit() {
        let from = [];
        for(let i = 1; i <= 5; i++) {
            const benzo = Helpers.inputVal('benzo-' + i);
            const dosage = Helpers.inputVal('dosage-' + i);
            if(benzo && dosage) {
                from.push({
                    benzo: benzo,
                    dosage: dosage,
                });
            }
        }
        window.visit(
            '/#/benzo-conversion-result',
            Helpers.createNewEvent(),
            {
                'from': from,
                'conversionDrug': Helpers.inputVal('conversion-drug'),
            }
        );
    }

    render(event, args) {
        let context = {
            count: [1,2,3,4,5],
        };

        let benzos = {};
        for(let med in this.data) {
            if(!this.data.hasOwnProperty(med)) {
                continue;
            }
            if(this.data[med].type.indexOf('benzodiazepine') === -1) {
                continue;
            }
            benzos[med] = this.data[med];
        }
        context['benzos'] = benzos;

        Gui.render('benzoconversion.html', context);
        Gui.setTitle('Benzodiazepines');
        this.selectFields = M.FormSelect.init($('select'));

        Gui.showBackButton();
        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                'benzo-conversion', 'benzo-conversion', this.submit.bind(this)
            )
        );
    }

}
