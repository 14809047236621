/**
 * Debug module.
 */

"use strict";

/**
 * Show a log message
 * @param {any} msg - the log message
 */
export function log(msg) {
    if(window.DEBUG) {
        console.log(msg);
    }
}
