/**
 * Gui module.
 * @module gui
 */

"use strict";

import * as Constants from './constants';
import * as Debug from './debug';
import * as Helpers from './helpers';
import * as I8n from './i8n';
import * as Session from './session';
import * as Signals from './signals';
import * as State from './state';

let env;

/**
 * Initialize the Gui system, set up Nunjucks.
 */
export function initGui(){
    env = nunjucks.configure({ autoescape: true });
    initSidebar();
    initFilters();
}

/**
 * Initialize Nunjucks filters
 */
function initFilters() {
    env.addFilter('isArray', (obj) => {return Array.isArray(obj);});
    env.addFilter('isDefined', Helpers.isDefined);
    env.addFilter('trans', translateString);
    env.addGlobal('getLanguage', Session.getLanguage);
    env.addGlobal('keyCount', Helpers.keyCount);
    env.addGlobal('favoriteName', favoriteName);
}

function initSidebar() {
    let elems = document.querySelectorAll('.sidenav');
    let instances = M.Sidenav.init(elems, {
        edge: 'right'
    });
}

/**
 * Translates given string into the currently selected language and returns it
 * as a safe string for usage inside templates
 */
function translateString(str) {
    return safeString(I8n.translate(str));
}

function favoriteName(name) {
    return safeString(
        name
        .replace('->', '<img src="/images/shuffle-alt.svg" />')
        .replace('+', '<img src="/images/combine-alt.svg" />')
    );
}

/**
 * Renders a given template with a context using Nunjucks and return the
 * generated string
 * @param {string} template - i.e "home.html"
 * @param {object} context - the context object used for rendering
 * @return {string}
 */
export function renderToString(template, context={}){
    return env.render(template, context);
}


/**
 * Render a given template with a context using Nunjucks, and put the
 * result in the content div
 * @param {string} template - i.e "home.html"
 * @param {object} context - the context object used for rendering. If the
 *     context object contains a "helpPath" attribute, then that's used to
 *     modify the help icon link in the header.
 */
export function render(template, context={}) {
    Debug.log('[GUI] Render ' + template);
    // TODO: we might change the main container div
    $('div.container').html(env.render(template, context));
    window.scrollTo(0,0);
    $('a.external, a[rel=external]').attr('target', '_blank');
}

/**
 * Makes a given string safe for rendering
 * @param {string} str - i.e a rendered template
 * @returns {object} safe string
 */
export function safeString(str) {
    return new nunjucks.runtime.SafeString(String(str));
}

/**
 * Render a given string with a context using Nunjucks, and return
 * the result.
 * @param {string} template - i.e "<p>{{ greeting }} world</p>"
 * @param {object} context - the context object used for rendering, i.e {'greeting': 'Hello'}
 * @returns {string} rendered string
 */
export function renderString(template, context={}) {
    return env.renderString(template, context);
}

/**
 * 404 error page, shown when routing fails
 */
export function show404(){
    render('404.html');
}

export function showError(id, msg){
    $('p.error[data-id=' + id + ']').html(msg).fadeIn();
}

export function hideError(id, msg){
    $('p.error[data-id=' + id + ']').hide();
}

/**
 * Show a loading icon in the given element
 */
export function enableLoadingIcon(elem) {
    $(elem).addClass('has-loading-icon');
}

export function disableLink(elem) {
    $(elem).addClass('no-link');
}

export function enableLink(elem) {
    $(elem).removeClass('no-link');
}

/**
 * Find the icon span in the given element and disable it's loading status
 * @param {Object} elem - the DOM element
 */
export function disableLoadingIcon(elem) {
    $(elem).removeClass('has-loading-icon');
}

/**
 * Removes all loading icons
 */
export function disableLoadingIcons() {
    $('.has-loading-icon').removeClass('has-loading-icon');
}

/**
 * Hide the hamburger menu if it's popped open
 */
export function hideMenu() {
    $('div.menu').hide();
}

export function getActionElementById(id) {
    return $('a.action[data-id=' + id + ']');
}

export function loadingSpinnerHtml() {
    return '<span class="has-loading-icon"><span class="icon-spin-6"></span></span>';
}

/**
 * {string} newTitle
 */
export function setTitle(newTitle) {
    $('div.navbar-fixed .brand-logo').html(newTitle).removeClass('switch');
}

export function setSwitchTitle(from, to) {
    $('div.navbar-fixed .brand-logo').html(from + '<img src="/images/shuffle.svg" />' + to).addClass('switch');
}

export function setCombineTitle(from, to) {
    $('div.navbar-fixed .brand-logo').html(from + '<img src="/images/combine.svg" />' + to).addClass('switch');
}

export function showBackButton() {
    $('div.nav-wrapper a.back').show();
}

export function hideBackButton() {
    $('div.nav-wrapper a.back').hide();
}
export function closeMenu() {
    let instance = M.Sidenav.getInstance($('ul#slide-out')[0]).close();
}
