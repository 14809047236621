/**
 * MedCombine view module.
 */

"use strict";

import * as Gui from '../core/gui';
import * as Actions from '../core/actions';
import * as Api from '../core/api';
import * as State from '../core/state';
import * as Signals from '../core/signals';
import * as Debug from '../core/debug';
import * as Helpers from '../core/helpers';

/**
 * MedCombine view class
 */
export class MedCombineView {
    constructor() {
        this.viewName = 'MedCombineView';
        this.listenerIds = [];
        this.loadingTimeout = -1;
        this.actionHandlerIds = [];
        this.listenerIds = [];
        this.existingField = null;
        this.addField = null;
        this.data = State.getData();
        this.type = '';
    }

    destructor() {
        Signals.removeListeners(this.listenerIds);
        Actions.removeActionHandlers(this.actionHandlerIds);
    }

    submit() {
        const existingValue = $('select[name=existing]').val();
        const addValue = $('select[name=add]').val();
        window.visit('/#/combine-result/' + existingValue + '/' + addValue);
    }

    initAddField(items=[]) {
        let options = '<option disabled selected value="">Add moodstabilizer</option>';
        for(let option of items) {
            let name = Helpers.capitalize(option);
            if(this.data.hasOwnProperty(option)) {
                name = this.data[option].name;
            }
            options += '<option value="' + option + '">' + name + '</option>';
        }
        $('select[name=add]').html(options);
        $('select[name=add]').before('<label for="med-existing">Add</label>');
        this.addField = M.FormSelect.init($('select[name=add]'))[0];
    }

    selectExisting(element) {
        let name = $(element).val();
        if(name === '') {
            return this.initAddField();
        }

        if(this.addField) {
            this.addField.destroy();
            this.addField = null;
        }

        this.initAddField(Object.keys(this.data[name].combis));
    }

    render(event, args) {
        let context = {};

        console.log(this.data);

        let meds = {};
        for(let med in this.data) {
            if(!this.data.hasOwnProperty(med)) {
                continue;
            }
            if(this.data[med].type.indexOf('moodstabilizer') === -1) {
                continue;
            }
            meds[med] = this.data[med];
        }
        context['meds'] = meds;

        Gui.setTitle('Moodstabilizers');
        Gui.showBackButton();
        Gui.render('medscombine.html', context);

        this.existingField = M.FormSelect.init($('select[name=existing]'), {
            dropdownOptions: {
                onCloseEnd: this.selectExisting.bind(this)
            }
        })[0];

        this.initAddField();

        this.actionHandlerIds.push(
            Actions.registerActionHandler(
                'combine-med', 'combine-med', this.submit.bind(this)
            )
        );

    }
}
