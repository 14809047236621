/**
 * Signals module
 * @module signals
 */

"use strict";

import * as Api from './api';
import * as Debug from './debug';

let listeners = {};
let lastListenerId = 0;

/**
 * Attach a listener to a signal.
 * @param {string} signal - The signal to attach to.
 * @param {function} callback - The callback to attach.
 * @returns {number} - the id of the listener or false.
 */
export function addListener(signal, callback){
    if(!listeners.hasOwnProperty(signal)){
        listeners[signal] = [];
    }
    lastListenerId++;
    listeners[signal].push({
        id: lastListenerId,
        callback: callback,
    });

    return lastListenerId;
}

/**
 * Remove a listener from a signal.
 * @param {number} id - The id of the listener to remove.
 */
export function removeListener(id){
    for(const signal in listeners){
        if(!listeners.hasOwnProperty(signal)){
            continue;
        }
        for(let i = 0; i < listeners[signal].length; i++){
            if(listeners[signal][i].id === id){
                listeners[signal].splice(i, 1);
                if(listeners[signal].length === 0){
                    delete listeners[signal];
                }
                return;
            }
        }
    }
}

/**
 * Remove a list of listeners from their signals
 * @param {array} ids - The list of listener ids
 */
export function removeListeners(ids) {
    for(let i = 0; i < ids.length; i++){
        removeListener(ids[i]);
    }
}

/**
 * Remove all the listeners. After doing this this app will process
 * none of the data received by the Websocket.
 */
export function removeAllListeners(){
    listeners = {};
    subscribedDevices = {};
}

/**
 * Return a list of signal names for the given message.
 * @param {object} message - The message to get the signals for.
 * @returns {string[]} - The list of signals.
 * @private
 */
function getSignalNamesForMessage(message){
    let signals = [];
    // Add global signal
    signals.push(message.tag);
    return signals;
}

/**
 * Send a signal to the listeners.
 * @param {object} message - The data from the websocket.
 */
export function emit(message){
    /*
     * Example content of a message:
     * {
     *     "tag": "device/parameter",
     *     "data": {
     *        "device": "1532000000A1114210",
     *        "parameter": "PARAM_QID_CM_TINY_DEVICE_NAME",
     *        "value": "0d02h50m37s"
     *     }
     *  }
     */
    const signals = getSignalNamesForMessage(message);
    signals.forEach(signal => {
        if(listeners.hasOwnProperty(signal)){
            listeners[signal].forEach(listener => {
                listener.callback(message);
            });
        }
    });
}


/**
 * Send a signal to the listeners with only a tag and a message string
 * @param {string} tag
 * @param {string} messageStr
 */
export function quickEmit(tag, messageStr=''){
    emit({
        'tag': tag,
        'data': {
            'msg': messageStr,
        },
    });
}
